import React, { FC } from 'react';
import { FontWeights } from 'core/CssVariables';
import { Text } from 'components/Base/Typography';
import NotificationItem from 'components/Base/Layout/Notifications/components/NotificationItem';
import { TConvertedToPetAcknowledgmentUI } from 'data/types/animal.types';
import NotificationActions from './NotificationActions';
import NotificationInfo from './NotificationInfo';

type TConvertedToPetNotificationProps = {
  data: TConvertedToPetAcknowledgmentUI[];
  borderedNotificationItems: string[];
};

const ConvertedToPetNotification: FC<TConvertedToPetNotificationProps> = ({
  data,
  borderedNotificationItems,
}) => {
  if (data.length === 0) return null;
  return (
    <>
      {data.map(animal => (
        <NotificationItem
          hasTopBorder={borderedNotificationItems.includes(animal.id)}
          key={animal.id}
          textSection={
            <>
              <Text fontWeight={FontWeights.SemiBold}>
                {animal.name} ({animal.species}) has been converted to PET{' '}
              </Text>
              <NotificationInfo animal={animal} />
            </>
          }
          actionSection={<NotificationActions animal={animal} />}
        />
      ))}
    </>
  );
};

export default ConvertedToPetNotification;
