import { Menu } from 'components/Base/Menu';
import useFilterRoutes from 'hooks/useFilterRoutes';
import { PrivateRouteType } from 'routes/Routes.types';
import MenuPathItem from '../../MenuItem/MenuPathItem';

const MainMenu = () => {
  const routes = useFilterRoutes();

  const menuItems: PrivateRouteType[] = routes.filter(
    item => item.showDesktopMenu,
  );
  return (
    <Menu mode="horizontal" disabledOverflow>
      {menuItems.map(route => (
        <MenuPathItem key={route.path} menuItem={route} />
      ))}
    </Menu>
  );
};

export default MainMenu;
