import {
  useAnimalsConversionAcknowledgmentQuery,
  useGetIncompleteAnimalsQuery,
} from 'redux/apiSlice/animal.slice';
import { RoutesPaths } from 'routes/Routes.types';
import { useLocation } from 'react-router-dom';
import CompleteAnimalBar from './CompleteAnimalBar';
import ConvertedToPetNotification from './ConvertedToPetNotification';

const Notifications = () => {
  const location = useLocation();
  const { data: incompleteAnimals } = useGetIncompleteAnimalsQuery();
  const { data: animalConversionAcknowledgmentData } =
    useAnimalsConversionAcknowledgmentQuery();

  const hideNotification = location.pathname.includes(
    RoutesPaths.KNOWLEDGE_BASE,
  );
  if (
    hideNotification ||
    !incompleteAnimals ||
    !animalConversionAcknowledgmentData
  ) {
    return null;
  }
  const animalConversionsToNotify = animalConversionAcknowledgmentData.slice(
    0,
    2,
  );

  const borderedNotificationItems = [];

  if (animalConversionsToNotify.length > 0) {
    if (incompleteAnimals.count > 0) {
      borderedNotificationItems.push(
        animalConversionsToNotify[0].id,
        ...(animalConversionsToNotify.length > 1
          ? [animalConversionsToNotify[1].id]
          : []),
      );
    } else if (animalConversionsToNotify.length === 2) {
      borderedNotificationItems.push(animalConversionsToNotify[0].id);
    }
  }

  return (
    <>
      <CompleteAnimalBar data={incompleteAnimals} />
      <ConvertedToPetNotification
        borderedNotificationItems={borderedNotificationItems}
        data={animalConversionsToNotify}
      />
    </>
  );
};

export default Notifications;
