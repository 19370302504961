import { useGetTenantProfileQuery } from 'redux/apiSlice/tenant.slices';
import useDeviceType from 'hooks/useDeviceType';
import MobileHeader from './components/MobileHeader';
import DesktopHeader from './components/DesktopHeader';

const Header = () => {
  useGetTenantProfileQuery(null);
  const { isMobile } = useDeviceType();

  return <>{isMobile ? <MobileHeader /> : <DesktopHeader />}</>;
};

export default Header;
