import React, { FC } from 'react';
import { Text } from 'components/Base/Typography';
import { Colors } from 'core/CssVariables';
import useDeviceType from 'hooks/useDeviceType';
import { TOnChangeParam, TSelectProps, TValueType } from './Select.types';
import { SSelect } from './Select.styles';

const Select: FC<TSelectProps> = ({ label, className, ...props }) => {
  const { isMobile } = useDeviceType();

  return (
    <div className={className}>
      {label && (
        <Text fontLevel={6} color={Colors.LightGrey} className="mb-1">
          {label}
        </Text>
      )}
      <SSelect
        isMobile={isMobile}
        menuPosition="fixed"
        menuShouldBlockScroll
        {...props}
      />
    </div>
  );
};

export default Select;

export type { TValueType, TOnChangeParam };
