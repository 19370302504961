import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EDocPacketUrls } from 'data/urls/docPacket.url';
import { TFile } from 'data/types/storage.types';
import {
  TDocPacketUI,
  TDocPacketGetUI,
  TNameUpdateUI,
  TPhoneUpdateUI,
  TEmailUpdateUI,
  TFaxUpdateUI,
  THPVisitationUpdateUI,
  TDocPacketGet,
  THPLetterUpdateUI,
  ETemplateTypeUI,
  TDocPacketInfoUI,
} from 'data/types/docPacket.types';
import {
  createHPDocConvertor,
  hpNameUpdateConvertor,
  hpEmailUpdateConvertor,
  hpFaxUpdateConvertor,
  hpPhoneUpdateConvertor,
  hpVisitationUpdateConvertor,
  getHPDocPacketsConvertor,
  hpLetterUpdateConvertor,
  templateTypeConvertor,
  createHPDocInfoConvertor,
} from 'data/convertors/docPacket.convertors';

const baseUrl = `${EDocPacketUrls.PROFILE}/${EDocPacketUrls.HP_DOCUMENT_PACKET}`;

export const docPacketApi = authSplitApi('docPacket', [
  'docPacket',
  'docPackets',
]).injectEndpoints({
  endpoints: (build) => ({
    createHPDocPacket: build.mutation<string, TDocPacketUI>({
      query(body) {
        return {
          url: baseUrl,
          method: 'POST',
          body: createHPDocConvertor.toDb(body),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    getHPDocPackets: build.query<TDocPacketGetUI[], void>({
      query() {
        return {
          url: baseUrl,
          method: 'GET',
        };
      },
      transformResponse: (data: TDocPacketGet[]) =>
        data.map((item) => getHPDocPacketsConvertor.fromDb(item)),
      providesTags: ['docPackets'],
    }),
    updateHPName: build.mutation<void, TNameUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_CONTACT}/${EDocPacketUrls.NAME}`,
          method: 'PATCH',
          body: hpNameUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    updateHPPhone: build.mutation<void, TPhoneUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_CONTACT}/${EDocPacketUrls.PHONE}`,
          method: 'PATCH',
          body: hpPhoneUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    updateHPEmail: build.mutation<void, TEmailUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_CONTACT}/${EDocPacketUrls.EMAIL}`,
          method: 'PATCH',
          body: hpEmailUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    updateHPFax: build.mutation<void, TFaxUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_CONTACT}/${EDocPacketUrls.FAX_NUMBER}`,
          method: 'PATCH',
          body: hpFaxUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    updateHPVisitation: build.mutation<void, THPVisitationUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_VISITATION}`,
          method: 'PATCH',
          body: hpVisitationUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    updateHPLetter: build.mutation<void, THPLetterUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_LETTER}`,
          method: 'PATCH',
          body: hpLetterUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    deleteHPDocPacket: build.mutation<void, string>({
      query(documentPacketId) {
        return {
          url: `${baseUrl}`,
          method: 'DELETE',
          body: { documentPacketId },
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    getTemplate: build.mutation<TFile, ETemplateTypeUI>({
      query(templateType) {
        return {
          url: `${EDocPacketUrls.TEMPLATES}`,
          method: 'GET',
          params: { docType: ETemplateTypeUI.MENTAL_HEALTH_PROFESSIONAL },
        };
      },
    }),
    addHPDocPacketInfo: build.mutation<void, TDocPacketInfoUI>({
      query(body) {
        return {
          url: baseUrl,
          method: 'PUT',
          body: createHPDocInfoConvertor.toDb(body),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
  }),
});

export const {
  useCreateHPDocPacketMutation,
  useGetHPDocPacketsQuery,
  useUpdateHPEmailMutation,
  useUpdateHPFaxMutation,
  useUpdateHPNameMutation,
  useUpdateHPPhoneMutation,
  useUpdateHPVisitationMutation,
  useDeleteHPDocPacketMutation,
  useUpdateHPLetterMutation,
  useGetTemplateMutation,
  useAddHPDocPacketInfoMutation,
} = docPacketApi;
