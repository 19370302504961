import styled from 'styled-components';
import { Content } from '@landlord-tech/ui-kit-opp/dist/components/Layout';
import { ReactNode } from 'react';

export const SLayoutWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SMainContentWrapper = styled(Content)<{
  fullwith?: boolean;
  className?: string;
  children?: ReactNode;
}>`
  flex: 1;
  margin-top: 0px;
  padding-bottom: 24px;
  padding-left: ${({ fullwith }) => (fullwith ? '0px' : '20px')};
  padding-right: ${({ fullwith }) => (fullwith ? '0px' : '20px')};
`;
