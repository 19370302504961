import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'components/Base/Grid';
import Image from 'components/Base/Image';
import logo from 'assets/logo.svg';
import { RoutesPaths } from 'routes/Routes.types';
import ProfileMenu from './ProfileMenu';
// import Notifications from '../Notifications';
import MainMenu from './MainMenu';
import { SHeaderWrapper } from './DesktopHeader.styles';

const DesktopHeader = () => {
  const navigate = useNavigate();

  const onLogoClick = () => {
    navigate(`/app/${RoutesPaths.HOME}`);
  };

  return (
    <SHeaderWrapper>
      <Row justify="space-between" align="middle" className="container-lg">
        <Col>
          <Image
            src={logo}
            alt="opp-logo"
            onClick={onLogoClick}
            className="pointer"
          />
        </Col>
        <Col>
          <MainMenu />
        </Col>
        <Col>
          <Row>
            {/* <Notifications /> */}
            <ProfileMenu />
          </Row>
        </Col>
      </Row>
    </SHeaderWrapper>
  );
};

export default DesktopHeader;
