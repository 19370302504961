import { EHotjarEvents } from 'data/types/generalTypes';
import moment, { Moment } from 'moment';
import { Location, Params } from 'react-router-dom';

export const generateSpaceClassNames = (array: number[]) => {
  let classNames = ``;
  array.forEach((n, i) => {
    classNames += `
            .mx-${i} {
                margin: 0 ${n}px !important;
            }
            .my-${i} {
                margin: ${n}px 0 !important;
            }
            .m-${i} {
                margin: ${n}px !important;
            }
            .mt-${i} {
                margin-top: ${n}px !important;
            }
            .mb-${i} {
                margin-bottom: ${n}px !important;
            }
            .ml-${i} {
                margin-left: ${n}px !important;
            }
            .mr-${i} {
                margin-right: ${n}px !important;
            }
            .pt-${i} {
                padding-top: ${n}px !important;
            }
            .pb-${i} {
                padding-bottom: ${n}px !important;
            }
            .pl-${i} {
                padding-left: ${n}px !important;
            }
            .pr-${i} {
                padding-right: ${n}px !important;
            }
            .px-${i} {
                padding: 0 ${n}px !important;
            }
            .py-${i} {
                padding: ${n}px 0 !important;
            }
            .p-${i} {
                padding: ${n}px !important;
            }
        `;
  });

  return classNames;
};

export const defaultDateFormat = 'MM/DD/YYYY';
export const dateTimeFormat = 'MM/DD/YYYY HH:mm A';

export const regExps = {
  phoneNumber: /^\+?[1-9]\d{7,14}$/,
};

export const verificationCodeLength = 6;

export const formattedDate = (date?: Moment | string, format?: string) => {
  return moment(date).format(format || defaultDateFormat);
};

export const getRoutePath = (location: Location, params: Params): string => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname;
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

export const getDuration = (duration: number) => {
  if (duration / 60 > 1) {
    const minutes = duration - Math.trunc(duration / 60) * 60;
    return `${Math.trunc(duration / 60)}h ${
      minutes > 0 ? `${minutes}min` : ''
    }`;
  }

  return `${duration} min`;
};

export const getReadableDuration = (
  startDate: number | string | Moment,
  endDate: number | string | Moment,
) => {
  const start = moment(startDate);
  const end = moment(endDate);

  const duration = moment.duration(end.diff(start));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = '';

  if (years > 0) {
    result += `${years} year${years > 1 ? 's' : ''}, `;
  }
  if (months > 0) {
    result += `${months} month${months > 1 ? 's' : ''}, `;
  }
  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''}, `;
  }
  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''}, `;
  }
  if (minutes > 0) {
    result += `${minutes} minute${minutes > 1 ? 's' : ''}, `;
  }
  if (seconds > 0) {
    result += `${seconds} second${seconds > 1 ? 's' : ''}`;
  }

  // Trim trailing comma and space
  return result.replace(/,\s*$/, '');
};

export const throwHotjarEvent = (eventType: EHotjarEvents) => {
  // @ts-ignore
  if (window?.hj) {
    // @ts-ignore
    window.hj('event', eventType);
  }
};
export const getOrdinal = (number: number): string => {
  const remainder10 = number % 10;
  const remainder100 = number % 100;

  if (remainder10 === 1 && remainder100 !== 11) {
    return `${number}st`;
  }
  if (remainder10 === 2 && remainder100 !== 12) {
    return `${number}nd`;
  }
  if (remainder10 === 3 && remainder100 !== 13) {
    return `${number}rd`;
  }
  return `${number}th`;
};

export const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
  setTimeout(() => {
    if (!ref.current) return;
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, 0);
};
