import React, { useRef } from 'react';
import { EButtonColors } from 'components/Base/Button';
import { Icon, TIconNames } from 'components/Base/Icon';
import { Row } from 'components/Base/Grid';
import ToolTip from 'components/Base/ToolTip';
import { Colors } from 'core/CssVariables';
import { EAnimalTypesUI } from 'data/types/animal.types';
import ButtonDropdown from 'components/Base/ButtonDropdown';
import { TConvertAnimalActionProps } from './ConvertAnimalAction.types';
import { dropdownItems } from './ConvertAnimalAction.data';
import ConvertToPetModal from '../ConvertToPetModal';
import DoYouHaveDocumentsModal from '../DoYouHaveDocumentsModal';
import SADocumentationModal from '../SADocumentationModal';

const ConvertAnimalAction = ({
  animalId,
  animalType,
  onChangeTab,
  reason,
  actionTitle = 'Convert animal',
  isNotification,
  handleNotificationEvent,
}: TConvertAnimalActionProps) => {
  const convertToPetModalRef = useRef<{
    open: (animalId: string) => void;
  }>({
    open: () => {
      // do nothing
    },
  });

  const doYouHaveDocumentsModalRef = useRef<{
    open: (animalId: string) => void;
  }>({
    open: () => {
      // do nothing
    },
  });

  const SADocumentationModalRef = useRef<{
    open: (animalId: string) => void;
  }>({
    open: () => {
      // do nothing
    },
  });

  if (!animalType) {
    return null;
  }

  const renderConvertModal = {
    [EAnimalTypesUI.PET]: () => convertToPetModalRef.current.open(animalId),
    [EAnimalTypesUI.SA]: () => SADocumentationModalRef.current.open(animalId),
    [EAnimalTypesUI.ESA]: () =>
      doYouHaveDocumentsModalRef.current.open(animalId),
  };

  const renderItems = dropdownItems.filter(item => item.key !== animalType);

  return (
    <>
      <Row align="middle">
        <ButtonDropdown
          trigger={['click']}
          buttonDownIcon={!isNotification}
          outlined
          buttonSize="small"
          disabled={!!reason}
          buttonTitle={actionTitle}
          buttonColor={
            isNotification ? EButtonColors.Primary : EButtonColors.Info
          }
          buttonClassName={isNotification ? 'bg-white' : ''}
          iconColor={Colors.Blue}
          {...(!isNotification && {
            buttonIcon: <Icon icon={TIconNames.MOVE_OUT} size={13} />,
          })}
          onClickItem={item => renderConvertModal[item as EAnimalTypesUI]()}
          items={renderItems}
        />
        {!!reason && (
          <ToolTip placement="rightTop" title={reason} className="ml-1">
            <span>
              <Icon
                color={Colors.DarkGrey}
                icon={TIconNames.TOOLTIP}
                size={16}
                className="ml-1"
              />
            </span>
          </ToolTip>
        )}
      </Row>
      <ConvertToPetModal
        onChangeTab={onChangeTab}
        ref={convertToPetModalRef}
        animalType={animalType}
      />
      <DoYouHaveDocumentsModal
        ref={doYouHaveDocumentsModalRef}
        onChangeTab={onChangeTab}
        handleNotificationEvent={handleNotificationEvent}
      />
      <SADocumentationModal
        ref={SADocumentationModalRef}
        handleNotificationEvent={handleNotificationEvent}
      />
    </>
  );
};

export default ConvertAnimalAction;
