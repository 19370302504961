import { EAnimalTypesUI } from 'data/types/animal.types';
import { TIconNames } from 'components/Base/Icon';
import TextIcon from 'components/Base/TextIcon';
import {
  regexPatterns,
  validationRules,
} from 'components/Base/Form/validationRules';
import { TInitialValues } from './AnimalForm.types';

// TODO: Collect all strings in application in one place
export const animalFormFields = {
  type: {
    name: 'type',
    label: 'Animal type',
    placeholder: 'Select animal type',
    rules: [validationRules.required()],
  },
  profilePicture: {
    name: 'profilePicture',
  },
  name: {
    name: 'name',
    label: 'Animal name',
    placeholder: 'Type animal name',
    rules: [
      {
        required: true,
        message: 'This field is required',
        pattern: regexPatterns.onlyWhiteSpace,
      },
    ],
  },
  birthDate: {
    name: 'birthDate',
    label: 'Animal birthdate',
    placeholder: 'Select date',
    rules: [validationRules.required()],
  },
  species: {
    name: 'species',
    label: 'Species',
    placeholder: 'Select species',
    rules: [validationRules.required()],
  },
  breed: {
    name: 'breed',
    label: 'Breed (optional)',
    placeholder: 'Select breed',
  },
  weight: {
    name: 'weight',
    label: 'Weight (lbs, optional)',
    placeholder: 'Type weight',
    rules: [
      {
        required: false,
        message: 'Value must be greater than 0',
        pattern: regexPatterns.positiveNumbers,
      },
    ],
  },
  primaryColor: {
    name: 'primaryColor',
    label: 'Primary color',
    placeholder: 'Select primary color',
    rules: [validationRules.required()],
  },
  secondaryColor1: {
    name: 'secondaryColor1',
    placeholder: 'Select secondary color1',
    label: 'Secondary color1 (optional)',
  },
  secondaryColor2: {
    name: 'secondaryColor2',
    placeholder: 'Select secondary color2',
    label: 'Secondary color2 (optional)',
  },
  gender: {
    name: 'gender',
    label: 'Choose a gender',
    rules: [validationRules.required()],
  },
  neutered: {
    name: 'neutered',
    rules: [validationRules.required()],
  },
  houseTrained: {
    name: 'houseTrained',
    rules: [validationRules.required()],
  },
  isAggresive: {
    name: 'isAggressive',
    rules: [validationRules.required()],
  },
  requiredBecauseOfDisability: {
    name: 'requiredBecauseOfDisability',
    rules: [validationRules.required()],
  },
  trainedFor: {
    name: 'trainedFor',
    rules: [validationRules.required()],
    placeholder: 'Type here',
    label: 'Description',
  },
  aggressionInfo: {
    name: 'aggressionInfo',
    label: 'Please provide details about the incidents of aggression.',
    placeholder: 'Type here',
    rules: [
      {
        required: true,
        message: 'This field is required!',
      },
    ],
  },
  microchipNumber: {
    name: 'microchip',
    label: 'Microchip number (optional)',
    placeholder: 'Type microchip number',
    rules: [validationRules.microchipNumber()],
  },
  moveInDate: {
    name: 'moveInDate',
    placeholder: 'Select date',
    label: 'Move in date',
    rules: [validationRules.required()],
  },
};

export const initialValues: TInitialValues = {
  profilePicture: '',
  name: '',
  birthDate: '',
  species: null,
  breed: null,
  weight: '',
  primaryColor: null,
  secondaryColor1: null,
  secondaryColor2: null,
  gender: null,
  houseTrained: null,
  neutered: null,
};

export const typeDropdownItems = [
  { value: EAnimalTypesUI.PET, label: 'Pet' },
  { value: EAnimalTypesUI.ESA, label: 'Emotional Support Animal (ESA)' },
  { value: EAnimalTypesUI.SA, label: 'Service Animal (SA)' },
];

export const genderOptions = [
  {
    label: (
      <TextIcon
        wrap={false}
        icon={TIconNames.FEMALE}
        fontLevel={5}
        label="Female"
        size={12}
      />
    ),
    value: 'female',
  },
  {
    label: (
      <TextIcon
        wrap={false}
        icon={TIconNames.MALE}
        fontLevel={5}
        label="Male"
        size={12}
      />
    ),
    value: 'male',
  },
];
