import React, { FC } from 'react';
import { Col, Row } from 'components/Base/Grid';
import { Text } from 'components/Base/Typography';
import { Colors, FontWeights } from 'core/CssVariables';
import { TRadioLabelProps } from './RadioLabel.types';

const RadioLabel: FC<TRadioLabelProps> = ({ label, subtitle }) => {
  return (
    <Row>
      <Col span={24}>
        <Text fontLevel={5} fontWeight={FontWeights.SemiBold}>
          {label}
        </Text>
      </Col>
      <Col span={24}>
        <Text fontLevel={6} color={Colors.DarkGrey}>
          {subtitle}
        </Text>
      </Col>
    </Row>
  );
};

export default RadioLabel;
