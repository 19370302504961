import {
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/convertor.types';
import moment from 'moment';
import { formattedDate } from 'helpers/utils';
import {
  EAnimalResidencyStatusDB,
  EAnimalResidencyStatusUI,
  EResidencyStatusDB,
  EResidencyStatusUI,
  TAnimalResidencyDataDB,
  TAnimalResidencyDataUI,
  TCompleteTenantResidencyDB,
  TCompleteTenantResidencyUI,
  TCreateTenantResidencyDB,
  TCreateTenantResidencyUI,
  TResidencyData,
  TResidencyDataUI,
  TChangeResidencyFormDataDB,
  TChangeResidencyFormDataUI,
  TExistingAnimalsDB,
  TResidencyApplicationDataDB,
  TResidencyApplicationDataUI,
  TApplicantAnimalsUI,
  TApplicantAnimalsDB,
  TCompleteResidencyApplicationDB,
  TCompleteResidencyApplicationUI,
  TSetApplicantsMoveInDateDB,
  TSetApplicantsMoveInDateUI,
  EApplicationTypeUI,
  EApplicationTypeDB,
  TResidencyAnimalCountUI,
  TResidencyAnimalCountDB,
  EOnboardingStepDB,
  TResidencyWithAnimalsDataUI,
  TResidencyWithAnimalsDataDB,
  TResidencyAnimalInfoUI,
  TResidencyAnimalInfoDB,
} from 'data/types/residency.types';
import { TAnimalIncompleteUI } from 'data/types/animal.types';
import {
  EOnboardingSubStep,
  EOnboardingStepUI,
} from 'pages/public/Onboarding_Lite/OnboardingStepper/OnboardingStepper.types';
import {
  animalApplicationDataConverter,
  animalTypesConvertor,
} from './animal.convertors';
import {
  applicationStatusConvertor,
  selectOptionPredefinedConverter,
} from './general.converters';
import { tenantResidencyStatusConverter as residencyStatusConverter } from './tenant.convertors';

export const animalResidencyStatusConverter: TConvertor<
  EAnimalResidencyStatusUI,
  EAnimalResidencyStatusDB
> = {
  fromDb: data => {
    const dataMapper: {
      [key in EAnimalResidencyStatusDB]: EAnimalResidencyStatusUI;
    } = {
      [EAnimalResidencyStatusDB.ACTIVE]: EAnimalResidencyStatusUI.ACTIVE,
      [EAnimalResidencyStatusDB.TERMINATED]:
        EAnimalResidencyStatusUI.TERMINATED,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: {
      [key in EAnimalResidencyStatusUI]: EAnimalResidencyStatusDB;
    } = {
      [EAnimalResidencyStatusUI.ACTIVE]: EAnimalResidencyStatusDB.ACTIVE,
      [EAnimalResidencyStatusUI.TERMINATED]:
        EAnimalResidencyStatusDB.TERMINATED,
    };
    return dataMapper[data];
  },
};

export const applicantAnimalsConverter: TFetchConverter<
  TApplicantAnimalsUI,
  TApplicantAnimalsDB
> = {
  fromDb: data => {
    return {
      id: data.animalId,
      name: data.name,
      type: animalTypesConvertor.fromDb(data.type),
      applicationId: data.applicationId,
      status: applicationStatusConvertor.fromDb(data.status),
      typeConfirmed: data.typeConfirmed,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : undefined,
    };
  },
};

export const animalResidenciesConverter: TFetchConverter<
  TAnimalResidencyDataUI,
  TAnimalResidencyDataDB
> = {
  fromDb: data => {
    return {
      id: data.animalId,
      name: data.name,
      type: animalTypesConvertor.fromDb(data.type),
      status: animalResidencyStatusConverter.fromDb(data.status),
      typeConfirmed: data.typeConfirmed,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : undefined,
    };
  },
};
export const existingAnimalsConverter: TFetchConverter<
  TAnimalIncompleteUI,
  TExistingAnimalsDB
> = {
  fromDb: data => {
    return {
      id: data.animalId,
      name: data.name,
      type: animalTypesConvertor.fromDb(data.type),
      applicationId: data.applicationId,
      selected: data.selected,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : undefined,
      hasApplication: data.hasApplication,
    };
  },
};

type TOnboardingStepAndSubstep = {
  step: EOnboardingStepUI;
  subStep?: EOnboardingSubStep;
};

const getOnboardingStepAndSubstep = (
  data: TResidencyData,
): TOnboardingStepAndSubstep => {
  const { step } = data;
  switch (step) {
    case EOnboardingStepDB.RESIDENCY_FORM_CREATE:
      return {
        step: EOnboardingStepUI.RESIDENCY_INFORMATION,
        subStep: EOnboardingSubStep.CHOOSE_APPLICATION_TYPE,
      };
    case EOnboardingStepDB.RESIDENCY_FORM_COMPLETE:
      return {
        step: EOnboardingStepUI.RESIDENCY_INFORMATION,
        subStep: EOnboardingSubStep.FILL_APPLICATION_INFO,
      };
    case EOnboardingStepDB.RESIDENCY_ANIMAL_COUNT:
      return {
        step: EOnboardingStepUI.ANIMAL_INFORMATION,
        subStep: EOnboardingSubStep.ANIMAL_COUNT,
      };
    case EOnboardingStepDB.RESIDENCY_ANIMAL_CREATE:
      return {
        step: EOnboardingStepUI.ANIMAL_INFORMATION,
        subStep: EOnboardingSubStep.ANIMAL_CREATE,
      };
    case EOnboardingStepDB.RESIDENCY_ANIMAL_COMPLETE:
      return {
        step: EOnboardingStepUI.ANIMAL_INFORMATION,
        subStep: EOnboardingSubStep.ANIMAL_COMPLETE,
      };
    case EOnboardingStepDB.PAYMENT:
      return {
        step: EOnboardingStepUI.PAYMENT,
      };
    default:
      return {
        step: EOnboardingStepUI.RESIDENCY_INFORMATION,
        subStep: EOnboardingSubStep.FILL_APPLICATION_INFO,
      };
  }
};

export const residencyDataConverter: TFetchConverter<
  TResidencyDataUI,
  TResidencyData
> = {
  fromDb: data => {
    let status = null;
    if (data.resident !== null) {
      status = data.resident
        ? EApplicationTypeUI.RESIDENT
        : EApplicationTypeUI.APPLICANT;
    }

    return {
      id: data.id,
      leaseToDate: data.leaseToDate
        ? formattedDate(data.leaseToDate)
        : undefined,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : undefined,
      propertyManagementLogo: data.propertyManagementLogo,
      propertyManagementName: data.propertyManagementName,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      leasePacketId: data.leasePacketId,
      hasAnimal:
        typeof data.hasAnimal === 'boolean' ? data.hasAnimal : undefined,
      animalResidencies: (data.animals || []).map(
        animalResidenciesConverter.fromDb,
      ),
      existingAnimals: (data.existingAnimals || []).map(
        existingAnimalsConverter.fromDb,
      ),
      propertyManagementId: data.propertyManagementId,
      applicantAnimals: (data.applicantAnimals || []).map(
        applicantAnimalsConverter.fromDb,
      ),
      propertyId: data.propertyId,
      onboardingStateId: data.onboardingStateId,
      leaseNextDate: data.leaseNextDate,
      status,
      animalCount: data.animalCount,
      leasePacketCode: data.leasePacketCode,
      roommateAnimalCount: data.roommateAnimalCount,
      roommateHasAnimal: data.roommateHasAnimal,
      ...getOnboardingStepAndSubstep(data),
      isPaymentOn: data.isPaymentOn,
    };
  },
};

export const applicationTypeConverter: TCreateConverter<
  EApplicationTypeUI,
  EApplicationTypeDB
> = {
  toDb: data => {
    const dataMapper: {
      [key in EApplicationTypeUI]: EApplicationTypeDB;
    } = {
      [EApplicationTypeUI.APPLICANT]: EApplicationTypeDB.APPLICANT,
      [EApplicationTypeUI.RESIDENT]: EApplicationTypeDB.RESIDENT,
    };
    return dataMapper[data];
  },
};

export const tenantResidencyCreateConverter: TCreateConverter<
  TCreateTenantResidencyUI,
  TCreateTenantResidencyDB
> = {
  toDb: data => {
    return {
      state: applicationTypeConverter.toDb(data.state),
      leasePacketId: data.leasePacketId,
      leaseTo: formattedDate(data.endTime, 'YYYY-MM-DD'),
      moveIn: formattedDate(data.startTime, 'YYYY-MM-DD'),
      nextLeaseTo:
        data.secondEndTime && formattedDate(data.secondEndTime, 'YYYY-MM-DD'),
      propertyName: data.propertyName.label,
      unitName: data.unit.label,
      unitId:
        data.unit.value.unitId !== data.unit.label
          ? data.unit.value.unitId
          : undefined,
      hasAnimal: data.haveAnimal,
      roommateHasAnimal: data.roommateHasAnimal,
      animalDetails: data.haveAnimal
        ? [
            ...(data.animals || []).map(animal => ({
              animalId: animal.id,
              moveIn:
                animal.moveInDate &&
                moment(animal?.moveInDate).format('YYYY-MM-DD'),
              retractReason: animal?.reason,
            })),
            ...(data.existingAnimals || []).map(animal => ({
              animalId: animal.id,
              moveIn: moment(animal?.moveInDate || data.startTime).format(
                'YYYY-MM-DD',
              ),
              retractReason: animal?.reason,
            })),
          ]
        : null,
      animalCount: data.animalCount,
      roommateAnimalCount: data.roommateAnimalCount,
    };
  },
};

export const tenantResidencyCompleteConverter: TCreateConverter<
  TCompleteTenantResidencyUI,
  TCompleteTenantResidencyDB
> = {
  toDb: data => {
    return {
      residencyId: data.residencyId,
      hasAnimal: data.haveAnimal,
      roommateHasAnimal: data.roommateHasAnimal,
      animalDetails: data.haveAnimal
        ? [
            ...(data.animals || []).map(animal => ({
              animalId: animal.id,
              moveIn: moment(animal.moveInDate).format('YYYY-MM-DD'),
            })),
            ...(data.existingAnimalList || []).map(animal => ({
              animalId: animal.id,
              moveIn: moment(animal.moveInDate || data.moveInDate).format(
                'YYYY-MM-DD',
              ),
            })),
          ]
        : [],
      unitNumber: data.unitNumber,
      leaseTo: moment(data.leaseToDate).format('YYYY-MM-DD'),
      moveIn: moment(data.moveInDate).format('YYYY-MM-DD'),
    };
  },
};
export const updateResidencyDataConverter: TCreateConverter<
  TChangeResidencyFormDataUI,
  TChangeResidencyFormDataDB
> = {
  toDb: data => {
    return {
      propertyName: data.propertyName,
      id: data.id,
      unitNumber: data.unitNumber.label,
      moveInDate: data.moveInDate.format('YYYY-MM-DDTHH:mm:ss'),
      leaseToDate: data.leaseToDate.format('YYYY-MM-DDTHH:mm:ss'),
    };
  },
};

export const tenantResidencyStatusConverter: TConvertor<
  EResidencyStatusUI,
  EResidencyStatusDB
> = {
  fromDb: data => {
    const dataMapper: { [key in EResidencyStatusDB]: EResidencyStatusUI } = {
      [EResidencyStatusDB.FORMER_RESIDENT]: EResidencyStatusUI.FORMER_RESIDENT,
      [EResidencyStatusDB.RESIDENT]: EResidencyStatusUI.RESIDENT,
      [EResidencyStatusDB.APPLICANT]: EResidencyStatusUI.APPLICANT,
      [EResidencyStatusDB.DENIED]: EResidencyStatusUI.DENIED,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: { [key in EResidencyStatusUI]: EResidencyStatusDB } = {
      [EResidencyStatusUI.FORMER_RESIDENT]: EResidencyStatusDB.FORMER_RESIDENT,
      [EResidencyStatusUI.RESIDENT]: EResidencyStatusDB.RESIDENT,
      [EResidencyStatusUI.APPLICANT]: EResidencyStatusDB.APPLICANT,
      [EResidencyStatusUI.DENIED]: EResidencyStatusDB.DENIED,
    };
    return dataMapper[data];
  },
};

export const residencyApplicationDataConverter: TFetchConverter<
  TResidencyApplicationDataUI,
  TResidencyApplicationDataDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : '',
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : '',
      propertyManagementLogo: data.propertyManagementLogo,
      propertyManagementName: data.propertyManagementName,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      hasAnimal:
        typeof data.hasAnimal === 'boolean' ? data.hasAnimal : undefined,

      existingAnimals: (data.existingAnimals || []).map(
        existingAnimalsConverter.fromDb,
      ),
      propertyManagementId: data.propertyManagementId,
      leasePacketId: data.leasePacketId,
      applicantAnimals: (data.applicantAnimals || []).map(
        applicantAnimalsConverter.fromDb,
      ),
      roommateHasAnimal: data.roommateHasAnimal,
    };
  },
};

export const tenantResidencyApplicationCreateConverter: TCreateConverter<
  TCompleteResidencyApplicationUI,
  TCompleteResidencyApplicationDB
> = {
  toDb: data => {
    return {
      residencyApplicationId: data.residencyApplicationId,
      leaseTo: data.moveInDate,
      moveIn: data.leaseToDate,
      unitNumber: data.unitNumber,
      hasAnimal: data.haveAnimal,
      roommateHasAnimal: data.roommateHasAnimal,
      animalIds: data.haveAnimal
        ? [
            ...(data.animals || []).map(animal => animal.id),
            ...(data.existingAnimals || []).map(animal => animal.id),
          ]
        : [],
    };
  },
};
export const setApplicantsMoveInDateConverter: TCreateConverter<
  TSetApplicantsMoveInDateUI,
  TSetApplicantsMoveInDateDB
> = {
  toDb: data => {
    return {
      applicationId: data.id,
      moveInDate: moment(data.moveInDate).format('YYYY-MM-DD'),
    };
  },
};

export const residencyAnimalCountConverter: TCreateConverter<
  TResidencyAnimalCountUI,
  TResidencyAnimalCountDB
> = {
  toDb: data => {
    return {
      residencyId: data.residencyId,
      animalCount: data.animalCount || 0,
      animalCountChangeReason: data.animalCountChangeReason,
      roommateAnimalCount: data.roommateAnimalCount || 0,
      roommateAnimalCountChangeReason: data.roommateAnimalCountChangeReason,
    };
  },
};

export const residencyAnimalInfoConverter: TFetchConverter<
  TResidencyAnimalInfoUI,
  TResidencyAnimalInfoDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      type: animalTypesConvertor.fromDb(data.type),
      breed: data.breed && selectOptionPredefinedConverter.fromDb(data.breed),
      specie:
        data.specie && selectOptionPredefinedConverter.fromDb(data.specie),
      profilePicture: data.profilePicture,
      complete: data.complete,
    };
  },
};

export const residencyDataWithAnimalsConverter: TFetchConverter<
  TResidencyWithAnimalsDataUI,
  TResidencyWithAnimalsDataDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      status: residencyStatusConverter.fromDb(data.status),
      propertyManagementLogo: data.propertyManagementLogo,
      propertyManagementName: data.propertyManagementName,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : undefined,
      leaseToDate: data.leaseToDate
        ? formattedDate(data.leaseToDate)
        : undefined,
      nextLeaseToDate: data.nextLeaseToDate
        ? formattedDate(data.nextLeaseToDate)
        : undefined,
      animalResidencyId: data.animalResidencyId,
      roommateAnimalCount: data.roommateAnimalCount,
      statusReason: data.statusReason,
      animals: (data?.animals || []).map(animal => {
        return {
          ...animalApplicationDataConverter.fromDb(animal),
          animal: residencyAnimalInfoConverter.fromDb(animal.animal),
        };
      }),
      active: data.active,
      canEdit: data.editAllowed,
      propertyId: data.propertyId,
      unitId: data.unitId,
      leasePacketId: data.leasePacketId,
    };
  },
};
