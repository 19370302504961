import { useState } from 'react';
import Button, { EButtonColors } from 'components/Base/Button';
import { Text } from 'components/Base/Typography';
import { TConvertedToPetAcknowledgmentUI } from 'data/types/animal.types';
import useDeviceType from 'hooks/useDeviceType';
import { Colors } from 'core/CssVariables';
import { SecondaryTextColor } from 'core/CssVariablesLite';
import {
  hudGuidelinesLink,
  notRequiredForDisabilityReason,
  notRequiredForDisabilityReplaceReason,
} from '../../../../Shared/AnimalDetails/AnimalOtherInfo/ConversionTabContent/ConversionTabContent.data';

const NotificationInfo = ({
  animal,
}: {
  animal: TConvertedToPetAcknowledgmentUI;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { convertedToPetReason } = animal;
  const { isMobile } = useDeviceType();

  const getConvertedToPetReason = () => {
    if (convertedToPetReason?.includes(notRequiredForDisabilityReason)) {
      return notRequiredForDisabilityReplaceReason;
    }

    return convertedToPetReason;
  };

  return (
    <Text color={SecondaryTextColor}>
      {isExpanded && (
        <>
          <Text color={SecondaryTextColor}>
            {animal.isReasonPredefined ? (
              <Text color={SecondaryTextColor}>
                {getConvertedToPetReason()}
                <Button
                  type="text"
                  onClick={() => window.open('mailto:support@ourpetpolicy.com')}
                >
                  <Text color={Colors.WarningColor}>
                    {' '}
                    &nbsp;support@ourpetpolicy.com
                  </Text>
                </Button>{' '}
                <Text color={SecondaryTextColor}>
                  . Please refer to this
                </Text>{' '}
                <Button
                  type="text"
                  onClick={() => window.open(hudGuidelinesLink)}
                >
                  <Text color={Colors.WarningColor}>document</Text>
                </Button>
                &nbsp;
                <Text color={SecondaryTextColor}> for HUD Guidelines. </Text>
              </Text>
            ) : (
              getConvertedToPetReason()
            )}
          </Text>
          <Button
            type="text"
            color={EButtonColors.Info}
            onClick={() => setIsExpanded(p => !p)}
          >
            &nbsp; See less
          </Button>
        </>
      )}

      {!isExpanded &&
        !isMobile &&
        (getConvertedToPetReason()?.length > 60 ? (
          <>
            <Text color={SecondaryTextColor}>
              {getConvertedToPetReason().slice(0, 60)}...{' '}
            </Text>
            <Button
              type="text"
              color={EButtonColors.Info}
              onClick={() => setIsExpanded(p => !p)}
            >
              &nbsp; See more
            </Button>
          </>
        ) : (
          <Text>{getConvertedToPetReason()}</Text>
        ))}
      {!isExpanded && isMobile && (
        <Button
          type="text"
          color={EButtonColors.Info}
          onClick={() => setIsExpanded(p => !p)}
        >
          &nbsp; See more
        </Button>
      )}
    </Text>
  );
};

export default NotificationInfo;
