import { EAnimalTypesUI } from 'data/types/animal.types';
import { TDropdownItem } from './ConvertAnimalAction.types';

export const dropdownItems: TDropdownItem[] = [
  { key: EAnimalTypesUI.PET, name: 'Pet', selected: false },
  { key: EAnimalTypesUI.SA, name: 'Service animal', selected: false },
  {
    key: EAnimalTypesUI.ESA,
    name: 'Emotional Support Animal',
    selected: false,
  },
];
