import React from 'react';
import {
  EAnimalTypesUI,
  EConvertedToPetResolve,
  TConvertedToPetAcknowledgmentUI,
} from 'data/types/animal.types';
import { Row, Col } from 'components/Base/Grid';
import {
  animalApi,
  useAnimalsConversionAcknowledgmentResolveMutation,
  // useGetAnimalMetadataQuery,
} from 'redux/apiSlice/animal.slice';
import { NotificationPrimaryButton } from 'components/Base/Layout/Notifications/components/NotificationButton';
import { useDispatch } from 'react-redux';
import ConvertAnimalAction from '../../../../Shared/AnimalDetails/AnimalActions/ConvertAnimal/ConvertAnimalAction';

const NotificationActions = ({
  animal,
}: {
  animal: TConvertedToPetAcknowledgmentUI;
}) => {
  const { id } = animal;
  // const { data: animalMetadata } = useGetAnimalMetadataQuery(animal.id);
  const dispatch = useDispatch();
  const [convertedToPetResolve] =
    useAnimalsConversionAcknowledgmentResolveMutation();

  const handleNotificationEvent = async (isRequest?: boolean) => {
    await convertedToPetResolve({
      animalId: id,
      status: isRequest
        ? EConvertedToPetResolve.REQUEST_TO_CONVERT
        : EConvertedToPetResolve.UNDERSTOOD,
    });
    dispatch(animalApi.util.invalidateTags(['convertedToPet']));
  };
  return (
    <Row gutter={[8, 8]} wrap={false}>
      <Col>
        <ConvertAnimalAction
          animalId={animal.id}
          animalType={EAnimalTypesUI.PET}
          actionTitle="Request to convert"
          isNotification
          handleNotificationEvent={() => handleNotificationEvent(true)}
        />
      </Col>
      <Col>
        <NotificationPrimaryButton onClick={() => handleNotificationEvent()}>
          I understand
        </NotificationPrimaryButton>
      </Col>
    </Row>
  );
};

export default NotificationActions;
