import { forwardRef, useImperativeHandle, useState } from 'react';
import toast from 'components/Base/Notification';
import { ETabActiveKeyContent } from 'components/Shared/AnimalDetails/AnimalOtherInfo/AnimalOtherInfo.data';
import { ModalDeleteConfirmation, ModalWidthEnum } from 'components/Base/Modal';
import { useConvertAnimalToPetMutation } from 'redux/apiSlice/animal.slice';
import { EText } from 'data/datas/genericData';
import { TRtkErrorType } from 'data/types/api.types';
import { TConvertToPetModalProps } from './ConvertToPetModal.types';

const ConvertToPetModal = forwardRef(
  ({ animalType, onChangeTab }: TConvertToPetModalProps, ref) => {
    const [open, setOpen] = useState(false);
    const [animalId, setAnimalId] = useState('');
    useImperativeHandle(ref, () => ({
      open: (id: string) => {
        setOpen(true);
        setAnimalId(id);
      },
    }));

    const [convertAnimalToPet, { isLoading }] = useConvertAnimalToPetMutation();

    const handleSubmit = async () => {
      try {
        await convertAnimalToPet({ animalId }).unwrap();
        setOpen(false);
        if (onChangeTab) {
          onChangeTab(ETabActiveKeyContent.CONVERSION);
        }
        toast({
          description: 'Animal has been successfully converted',
          type: 'success',
        });
      } catch (e) {
        setOpen(false);
        toast({
          description:
            (e as TRtkErrorType)?.data?.message || EText.DEFAULT_ERROR,
          type: 'error',
        });
      }
    };

    return (
      <ModalDeleteConfirmation
        title="Converting"
        subtitleText={`Are you sure you want to convert your ${animalType} animal to Pet animal?`}
        size={ModalWidthEnum.Small}
        visible={open}
        destroyOnClose
        onOk={handleSubmit}
        okText="Convert"
        onCancel={() => setOpen(false)}
        confirmLoading={isLoading}
      />
    );
  },
);

export default ConvertToPetModal;
