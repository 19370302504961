import { hudGuidelinesLink as oldHud } from 'components/Shared/StatusTag/StatusTag.data';

export const hudGuidelinesLink =
  'https://www.hud.gov/sites/dfiles/PA/documents/HUDAsstAnimalNC1-28-2020.pdf';

export const notRequiredForDisabilityReason =
  'converted to pet because not required for disability';
export const mainInfoReplaceText =
  'If you have any questions or feel we’ve reached this conclusion in error, please contact us at';
export const notRequiredForDisabilityReplaceReason = `The animal has been converted to a pet since you have indicated that the animal is not required for a disability. If you feel that this is an error, please contact us right away or submit a conversion request to begin the Assistance Animal verification process. You can reach us at `;
export const replaceText = ` support@ourpetpolicy.com or (208) 906-8886. Please refer to this document for ${hudGuidelinesLink} Guidelines.`;
export const oldReplaceText = `Please refer to this ${oldHud} for HUD Guidelines.`;
