import moment from 'moment';
import { formattedDate } from 'helpers/utils';
import {
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/convertor.types';
import {
  EApplicationStepName,
  EApplicationStepNameUi,
  EApplicationStepType,
  EApplicationStepTypeUi,
  EPropertyManagementStatusDB,
  EPropertyManagementStatusUI,
  TAnimalStepDetailDB,
  TAnimalStepDetailUI,
  TApplicationCreate,
  TApplicationCreateUI,
  TApplicationDetailDB,
  TApplicationDetailUI,
  TApplicationResidencyInfo,
  TApplicationResidencyInfoUI,
  TApplicationSignDB,
  TApplicationSignUI,
  TApplicationStep,
  TApplicationStepUi,
  TLeasePacket,
  TLeasePacketProperty,
  TLeasePacketPropertyUi,
  TLeasePacketUi,
  TLeasePacketUnit,
  TLeasePacketUnitUi,
  TPaymentDetail,
  TPaymentDetailUi,
  TPmAndLeasePacketStepDetailsDB,
  TPmAndLeasePacketStepDetailsUI,
  TPMStepDetails,
  TPMStepDetailsUI,
  TProfileStepDetailDB,
  TProfileStepDetailUI,
  TPropertyManagementDetailsDB,
  TPropertyManagementDetailsUI,
  TPropertyManagementForm,
  TPropertyManagementStepData,
  TPropertyManagementStepDataUI,
  TPropertyManagementUI,
  TReapplyApplicationDB,
  TReapplyApplicationUI,
  TRecordApplicationStatistics,
  TRecordApplicationStatisticsUI,
  TRetractApplicationDB,
  TRetractApplicationUI,
  TSignature,
  TSignatureUI,
} from 'data/types/application.types';
import {
  EMoveOutOrRetractReasonTypeUI,
  TAnimalDuplicationDB,
  TAnimalDuplicationUI,
  TCreateAnimalIncompleteDB,
  TCreateAnimalIncompleteUI,
  TPotentialDuplicationAnimalsDB,
  TPotentialDuplicationAnimalsUI,
} from 'data/types/animal.types';
import {
  applicationStatusConvertor,
  emailMaskConverter,
  failsConvertor,
} from './general.converters';
import {
  animalTypesConvertor,
  applicationAnimalConvertor,
  moveOutOrRetractReasonTypeConverter,
} from './animal.convertors';
import { applicationProfileConvertor } from './tenant.convertors';

export const applicationStepNameConvertor: TConvertor<
  EApplicationStepNameUi,
  EApplicationStepName
> = {
  fromDb: data => {
    return EApplicationStepNameUi[
      EApplicationStepName[data] as keyof typeof EApplicationStepNameUi
    ];
  },
  toDb: data => {
    return EApplicationStepName[
      EApplicationStepNameUi[data] as keyof typeof EApplicationStepName
    ];
  },
};

export const applicationStepTypeConvertor: TConvertor<
  EApplicationStepTypeUi,
  EApplicationStepType
> = {
  fromDb: data => {
    return EApplicationStepTypeUi[
      EApplicationStepType[data] as keyof typeof EApplicationStepTypeUi
    ];
  },
  toDb: data => {
    return EApplicationStepType[
      EApplicationStepTypeUi[data] as keyof typeof EApplicationStepType
    ];
  },
};

export const getApplicationSteps: TFetchConverter<
  TApplicationStepUi,
  TApplicationStep
> = {
  fromDb: data => ({
    stepImage: data.stepImage || undefined,
    subTitle: data.subtitle,
    stepName: applicationStepNameConvertor.fromDb(data.stepName),
    title: data.title,
    withWarnings: data.withWarnings,
    stepType: applicationStepTypeConvertor.fromDb(data.stepType),
  }),
};
export const getPaymentDetailConverter: TFetchConverter<
  TPaymentDetailUi,
  TPaymentDetail
> = {
  fromDb: data => ({
    currentPriceForGivenAnimal: data.currentPriceForGivenAnimal,
    fails: failsConvertor.fromDb(data.fails || {}),
    firstAnimalPriceInCents: data.firstAnimalPriceInCents,
    otherAnimalPriceInCents: data.otherAnimalPriceInCents,
    payedUntil: data.payedUntil,
    paymentMethod: data.paymentMethod,
  }),
};
export const getLeasePacketConverter: TFetchConverter<
  TLeasePacketUi,
  TLeasePacket
> = {
  fromDb: data => ({
    generatedDocUrl: data.generatedDocUrl || '',
    leasePacketId: data.leasePacketId,
    propertyManagementName: data.propertyManagementName,
    propertyManagementLogo: data.propertyManagementLogo,
    propertyManagerName: data.propertyManagerName,
    propertyManagerId: data.propertyManagerId,
  }),
};
export const getLeasePacketPropertyConverter: TFetchConverter<
  TLeasePacketPropertyUi,
  TLeasePacketProperty
> = {
  fromDb: data => ({
    propertyId: data.propertyId,
    name: data.name,
    picture: data.picture,
    isSingleFamily: data.singleFamily,
    city: data.city,
    state:
      data.state &&
      data.state.charAt(0).toUpperCase() + data.state.slice(1).toLowerCase(),
  }),
};
export const getLeasePacketUnitConverter: TFetchConverter<
  TLeasePacketUnitUi,
  TLeasePacketUnit
> = {
  fromDb: data => ({
    unitId: data.unitId,
    name: data.name,
  }),
};

export const propertyManagementConvertor: TFetchConverter<
  TPropertyManagementUI,
  TPropertyManagementForm
> = {
  fromDb: data => ({
    leasePacket: data.leasePacket.value,
    property: data.propertyName.value,
    unit: data.unit.value,
    moveIn: data.startTime,
    leaseTo: data.endTime,
  }),
};

export const applicationSignatureConvertor: TConvertor<
  TSignatureUI,
  TSignature
> = {
  toDb: data => ({
    name: data.name,
    date: formattedDate(data.date, 'YYYY-MM-DD'),
  }),
  fromDb: data => ({
    name: data.name,
    date: formattedDate(data.date),
  }),
};

export const signApplicationConvertor: TCreateConverter<
  TApplicationSignUI,
  TApplicationSignDB
> = {
  toDb: data => ({
    applicationId: data.applicationId,
    signature: applicationSignatureConvertor.toDb(data.signature),
    animalId: data.animalId,
  }),
};

export const createApplicationConvertor: TCreateConverter<
  TApplicationCreateUI,
  TApplicationCreate
> = {
  toDb: data => {
    return {
      moveInDate: formattedDate(data.animalMoveInDate, 'YYYY-MM-DD'),
      leasePacketId: data.leasePacketId,
      animalId: data.animalId,
      signature: applicationSignatureConvertor.toDb(data.signature),
      ...(data.status === EPropertyManagementStatusUI.APPLICANT && {
        residencyApplicationId: data.id,
      }),
      ...(data.status === EPropertyManagementStatusUI.RESIDENT && {
        tenantResidencyId: data.id,
      }),
    };
  },
};

export const recordApplicationStatisticsConvertor: TCreateConverter<
  TRecordApplicationStatisticsUI,
  TRecordApplicationStatistics
> = {
  toDb: data => {
    return {
      leasePacketId: data.leasePacketId,
      animalId: data.animalId,
    };
  },
};

export const pmStepDetailsConvertor: TFetchConverter<
  TPMStepDetailsUI,
  TPMStepDetails
> = {
  fromDb: data => ({
    startTime: data.moveInDate ? moment(data.moveInDate) : null,
    endTime: data.leaseToDate ? moment(data.leaseToDate) : null,
    propertyName: data.propertyName || undefined,
    unit: data.unitNumber
      ? { value: data.unitNumber, label: data.unitNumber }
      : undefined,
  }),
};

export const propertyManagementDetailsConvertor: TFetchConverter<
  TPropertyManagementDetailsUI,
  TPropertyManagementDetailsDB
> = {
  fromDb: data => {
    const {
      signature,
      propertyManagementLogo,
      propertyManagementName,
      propertyManagerName,
      leasePacketId,
      ...pmStepDetails
    } = data;
    const { unit, ...pmStepProps } =
      pmStepDetailsConvertor.fromDb(pmStepDetails);
    return {
      animalMoveInDate: data.animalMoveInDate,
      signature: signature
        ? applicationSignatureConvertor.fromDb(signature)
        : null,
      ...pmStepProps,
      unit: unit?.value,
      propertyManagementLogo,
      propertyManagementName,
      propertyManagerName,
      leasePacketId,
    };
  },
};

export const pmAndLeasePacketStepDetailsConvertor: TFetchConverter<
  TPmAndLeasePacketStepDetailsUI,
  TPmAndLeasePacketStepDetailsDB
> = {
  fromDb: data => {
    const { propertyManagementDetails, hasFails, ...stepData } = data;

    return {
      stepData: { hasFails, ...getApplicationSteps.fromDb(stepData) },
      propertyManagementDetails: propertyManagementDetailsConvertor.fromDb(
        propertyManagementDetails,
      ),
    };
  },
};

export const animalStepDetailConvertor: TFetchConverter<
  TAnimalStepDetailUI,
  TAnimalStepDetailDB
> = {
  fromDb: data => {
    const { animalDetails, hasFails, ...stepData } = data;
    return {
      stepData: { hasFails, ...getApplicationSteps.fromDb(stepData) },
      animalDetails: applicationAnimalConvertor.fromDb(animalDetails),
    };
  },
};

export const profileStepDetailConvertor: TFetchConverter<
  TProfileStepDetailUI,
  TProfileStepDetailDB
> = {
  fromDb: data => {
    const { tenantDetails, hasFails, ...stepData } = data;
    return {
      stepData: { hasFails, ...getApplicationSteps.fromDb(stepData) },
      tenantDetails: applicationProfileConvertor.fromDb(tenantDetails),
    };
  },
};

export const getApplicationDetailConvertor: TFetchConverter<
  TApplicationDetailUI,
  TApplicationDetailDB
> = {
  fromDb: data => {
    const [pmAndLeasePacketStepDetails, animalStepDetail, profileStepDetail] =
      data.applicationSteps;
    return {
      animalMoveInDate: data.animalMoveInDate,
      id: data.id,
      rejectReason: data.rejectReason,
      status: applicationStatusConvertor.fromDb(data.status),
      applicationSteps: {
        [EApplicationStepTypeUi.PROPERTY_MANAGEMENT]:
          pmAndLeasePacketStepDetailsConvertor.fromDb(
            pmAndLeasePacketStepDetails,
          ),
        [EApplicationStepTypeUi.ANIMAL]:
          animalStepDetailConvertor.fromDb(animalStepDetail),
        [EApplicationStepTypeUi.TENANT_PROFILE]:
          profileStepDetailConvertor.fromDb(profileStepDetail),
      },
    };
  },
};
export const propertyManagementStatusConvertor: TConvertor<
  EPropertyManagementStatusUI,
  EPropertyManagementStatusDB
> = {
  toDb: data => {
    const dataMapper: {
      [key in EPropertyManagementStatusUI]: EPropertyManagementStatusDB;
    } = {
      [EPropertyManagementStatusUI.APPLICANT]:
        EPropertyManagementStatusDB.APPLICANT,
      [EPropertyManagementStatusUI.RESIDENT]:
        EPropertyManagementStatusDB.RESIDENT,
    };
    return dataMapper[data];
  },
  fromDb: data => {
    const dataMapper: {
      [key in EPropertyManagementStatusDB]: EPropertyManagementStatusUI;
    } = {
      [EPropertyManagementStatusDB.APPLICANT]:
        EPropertyManagementStatusUI.APPLICANT,
      [EPropertyManagementStatusDB.RESIDENT]:
        EPropertyManagementStatusUI.RESIDENT,
    };
    return dataMapper[data];
  },
};

export const applicationResidencyInfoConverter: TFetchConverter<
  TApplicationResidencyInfoUI,
  TApplicationResidencyInfo
> = {
  fromDb: data => {
    return {
      id: data.id,
      leasePacketId: data.leasePacketId,
      leaseToDate: formattedDate(data.leaseToDate),
      moveInDate: formattedDate(data.moveInDate),
      propertyManagementId: data.propertyManagementId,
      propertyManagementLogo: data.propertyManagementLogo,
      propertyManagementName: data.propertyManagementName,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      policyId: data.policyId,
      policyName: data.policyName,
      petsAllowed: data.petsAllowed,
      status: propertyManagementStatusConvertor.fromDb(data.status),
    };
  },
};

export const propertyManagementStepDataConvertor: TFetchConverter<
  TPropertyManagementStepDataUI,
  TPropertyManagementStepData
> = {
  fromDb: data => {
    return {
      residencyInfos: (data.residencyInfos || []).map(item =>
        applicationResidencyInfoConverter.fromDb(item),
      ),
    };
  },
};
export const incompleteAnimalConverter: TCreateConverter<
  TCreateAnimalIncompleteUI,
  TCreateAnimalIncompleteDB
> = {
  toDb: data => {
    return {
      name: data.name,
      type: animalTypesConvertor.toDb(data.type.value),
      tenantResidencyId: data.tenantResidencyId,
      moveInDate: formattedDate(data.moveInDate, 'YYYY-MM-DD'),
      missingMoveInDateReason: data.missingMoveInDateReason,
      missingNameReason: data.missingNameReason,
      missingTypeReason: data.missingTypeReason,
    };
  },
};

export const retractApplicationConverter: TCreateConverter<
  TRetractApplicationUI,
  TRetractApplicationDB
> = {
  toDb: data => {
    return {
      applicationIds: data.id,
      reason: data.other
        ? { other: data.other }
        : {
            predefined: moveOutOrRetractReasonTypeConverter.toDb(
              data.reason as EMoveOutOrRetractReasonTypeUI,
            ),
          },
    };
  },
};

export const reapplyApplicationConverter: TCreateConverter<
  TReapplyApplicationUI,
  TReapplyApplicationDB
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      residencyId: data.residencyId,
      reason: data.reason,
      moveInDate: formattedDate(data.moveInDate, 'YYYY-MM-DD'),
    };
  },
};

export const potentialDuplicateAnimalsConverter: TFetchConverter<
  TPotentialDuplicationAnimalsUI,
  TPotentialDuplicationAnimalsDB
> = {
  fromDb: data => {
    return {
      ownerName: data.ownerName,
      ownerUsername: emailMaskConverter.fromDb(data.ownerUsername),
      animalId: data.animalId,
      animalName: data.animalName,
      animalType: data.animalType,
      animalSpecie: data.animalSpecie,
    };
  },
};

export const incompleteAnimalsConverte: TFetchConverter<
  TAnimalDuplicationUI,
  TAnimalDuplicationDB
> = {
  fromDb: data => {
    return {
      createdAnimalId: data.createdAnimalId,
      failure: data.failure,
      potentialDuplicateAnimals: data.potentialDuplicateAnimals?.map(item =>
        potentialDuplicateAnimalsConverter.fromDb(item),
      ),
    };
  },
};
