import styled from 'styled-components';
import { Row } from 'components/Base/Grid';
import { Colors } from 'core/CssVariables';

export const SNotificationRow = styled(Row)<{ hasTopBorder?: boolean }>`
  padding: 8px 50px;
  background-color: #f6911a33;
  border-top: ${({ hasTopBorder }) =>
    hasTopBorder && `1px solid ${Colors.Black}`};
  @media (max-width: 768px) {
    padding: 8px 20px;
    height: auto;
  }
`;

export const SNotificationRowContent = styled(Row)`
  width: 1320px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;
