import styled from 'styled-components';
import { Radio } from 'components/Base/Radio';

export const SRadio = styled(Radio)`
  &&.ant-radio-wrapper {
    :not(:first-child) {
      margin-top: 8px;
    }
    .ant-radio {
      margin: 0 8px 16px 0;
    }
  }
`;
