import { FC } from 'react';

export type PublicRouteType = {
  component: FC<unknown>;
  path: string;
  onlyDev?: boolean;
};

export type PrivateRouteType = {
  component: FC<unknown>;
  path: string;
  showDesktopMenu?: boolean;
  showMobileMenu?: boolean;
  icon?: string;
  title: string;
  subRoutes?: {
    path: string;
    title: string;
  }[];
};

export enum RoutesPaths {
  LOGIN = 'login',
  IMPERSONATION = 'impersonation/:id',
  UI_KIT = 'ui-kit',
  SIGNUP = 'signup',
  SOCIAL_AUTH = 'social-auth',
  VERIFY_BY_EMAIL = 'verify-by-email',
  RESET_PASSWORD = 'reset-password',
  NEW_PASSWORD = 'new-password',
  PROFILE = 'profile',
  ONBOARDING = 'onboarding',
  USER_PROFILE = 'user-profile',
  PAYMENT = 'payment',
  ANIMAL = 'animal',
  CONVERSION = 'conversion',
  PAGE_NOT_FOUND = '404',
  APPLICATION = 'application',
  PROPERTY_MANAGEMENT = 'property-management',
  LEASE_PACKET = 'lease-packet',
  LEASE_PACKET_CODE = 'lease-packet/:leasePacketCode',
  CURRICULUM = 'curriculum',
  COMPLAINT = 'complaint',
  NOTICE_DETAILS = 'notice/:noticeId',
  COMPLAINT_DETAILS = 'notice/complaint/:complaintId',
  CERTIFICATE = 'certificate/:courseId/:checkpointKey',
  REDIRECT = 'redirect',
  VISITATION = 'visitation',
  APPLICATION_DETAILS = '/application/application-detail',
  KNOWLEDGE_BASE = 'knowledge-base',
  HOME = 'home',
}
