/* eslint-disable @typescript-eslint/no-explicit-any */
export type TOptionTypeUi = {
  id?: string;
  __isNew__?: boolean;
  value: string | any;
  label: string;
};

export type TOptionType = {
  id?: string;
  value?: string;
};

export type TPredefinedOptionType = {
  predefinedId?: string;
  value?: string;
};

export enum EVerificationStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}
export enum EVerificationStatusUi {
  NOT_VERIFIED = 'Not verified',
  VERIFIED = 'Verified',
}

export type TProfilePictureUi = {
  name: string;
  url: string;
};

export enum EApplicationStatuses {
  PENDING = 'PENDING',
  REJECTED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
  RETRACTED = 'RETRACTED',
  ARCHIVED = 'ARCHIVED',
  MOVED_OUT = 'MOVED_OUT',
  MOVED_IN = 'MOVED_IN',
  CONDITIONALLY_MOVED_IN = 'CONDITIONALLY_MOVED_IN',
  CONDITIONALLY_APPROVED = 'CONDITIONALLY_APPROVED',
}

export enum EApplicationStatusesUi {
  PENDING = 'Pending',
  REJECTED = 'Not Approved',
  APPROVED = 'Approved',
  RETRACTED = 'Retracted',
  ARCHIVED = 'Archived',
  MOVED_OUT = 'Moved out',
  MOVED_IN = 'Moved In',
  CONDITIONALLY_MOVED_IN = 'Conditionally Moved in',
  CONDITIONALLY_APPROVED = 'Conditionally Approved',
  INCOMPLETE = 'Incomplete',
}

export enum EAnimalStatuses {
  INCOMPLETE = 'INCOMPLETE',
  PENDING = 'PENDING',
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
  AUTHORIZED = 'AUTHORIZED',
  UN_AUTHORIZED = 'UN_AUTHORIZED',
}

export enum EAnimalStatusesUi {
  INCOMPLETE = 'Incomplete',
  PENDING = 'Pending',
  NOT_VERIFIED = 'Not verified',
  VERIFIED = 'Verified',
  AUTHORIZED = 'Authorized',
  UN_AUTHORIZED = 'Unauthorized',
}

export type TPredefinedOptionTypeUi = TOptionTypeUi;

export type TCustomSpeciesOptionTypeUi = {
  id: null;
  value: string;
  predefined: boolean;
};

export type TCustomOptionTypeUi = {
  id: null;
  value: string;
};

export type TYesNoOptionType = 'Yes' | 'No' | null;

export type TVetRecordTypeUI = {
  predefined?: string;
  custom?: string;
};

export type TVetRecordType = {
  predefined: string;
  value: string;
};

export type TExpirationDateType = {
  expirable: boolean;
  date: string;
};

export type TFileDataType = {
  createdBy: string;
  createdDate: string;
  documentFileId: string;
  name: string;
  url: string;
};

export type TErrorField = {
  name: string[];
  errors: string[];
  warnings: string[];
};

export type TValidateFieldsError = {
  errorFields: TErrorField[];
  outOfDate: boolean;
};

export type TSelectValueUI = TOptionTypeUi | undefined;
export type TSelectValue = {
  predefinedId: string | null;
  value: string | null;
};

export enum EValueEditingStates {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET = 'SET',
}

export enum EValueEditingStatesUi {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  SET = 'SET',
}

export enum EVetRecordTypeEnumDB {
  'Parvo' = 'PARVO',
  'Rabies' = 'RABIES',
  'Spayed/Neutered' = 'SPAYED_NEUTERED',
  'Dog liability insurance' = 'DOG_LIABILITY_INSURANCE',
}
export enum EVetRecordTypeEnumUI {
  'PARVO' = 'Parvo',
  'RABIES' = 'Rabies',
  'SPAYED_NEUTERED' = 'Spayed/Neutered',
  'DOG_LIABILITY_INSURANCE' = 'Dog liability insurance',
}

export type TEditingField = {
  predefinedId: string | null;
  value: string | null;
  allowedOperations?: EValueEditingStates[];
};

export type TEditingFieldUi = {
  id: string;
  value: string;
  canEdit: boolean;
  canRemove: boolean;
  canAdd: boolean;
};

export type TEditingBooleanField = {
  value: boolean;
  allowedOperations: EValueEditingStates[];
};

export type TEditingBooleanFieldUi = {
  value: boolean;
  canEdit: boolean;
};

export type TEditingYesNoFieldUi = {
  value: TYesNoOptionType;
  canEdit: boolean;
};

export type TEditingYesNoField = TEditingBooleanField;

export enum EGenderUi {
  FEMALE = 'Female',
  MALE = 'Male',
}

export enum EGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum EReproductive {
  SPAYED = 'Spayed',
  NEUTERED = 'Neutered',
}

export enum EGenderValue {
  FEMALE = 'female',
  MALE = 'male',
}

export enum EFailTypeUI {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
export enum EFailType {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type TFailUI = { field: string; reason: string; type: EFailTypeUI };
export type TFail = { [key: string]: { reason: string; type: EFailType } };

export enum EFileTypeUI {
  VIDEO = 'video',
  PHOTO = 'image',
  OTHER = 'other',
}

export enum EFileTypeDB {
  VIDEO = 'VIDEO',
  PHOTO = 'PHOTO',
  OTHER = 'other',
}

export type TFileUI = {
  url: string;
  name: string;
  type: EFileTypeUI;
};

export type TFileDB = {
  url: string;
  name: string;
  type?: EFileTypeDB;
};

export type TStatusChangeInfoUI = {
  by?: string;
  date?: string;
  reason?: string;
  dueDate?: string;
};

export type TStatusChangeInfoDB = {
  by?: string;
  date?: string;
  reason?: string;
  dueDate?: string;
};

export enum ETextMessage {
  VACCINATION_NO_EXPIRE = 'Documentation is from a licensed veterinarian exempting my animal from a rabies vaccination due to the animals age/health condition.',
}

export enum EHotjarEvents {
  ANIMAL_DUPLICATE = 'Animal duplicate',
  TENANT_DUPLICATE = 'Tenant duplicate',
}

export enum EValidateionStatus {
  CREATION_ALLOWED = 'CREATION_ALLOWED',
  USERNAME_ALREADY_EXISTS = 'USERNAME_ALREADY_EXISTS',
  RESEND_INVITATION = 'RESEND_INVITATION',
  NO_TENANT_PROFILE = 'NO_TENANT_PROFILE',
}
export type TValidateUsername = {
  status: EValidateionStatus;
};
