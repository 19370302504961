import { NavLink } from 'react-router-dom';
import { TMenuPathItem } from 'components/Base/Layout/Header/Header.props';
import { SMenuItemWrapper } from '../MobileHeader/CollapsedMenu/CollapsedMenu.styles';

const MenuPathItem = ({
  menuItem,
  handleToggle = () => {
    /* do nothing */
  },
  hasSubMenu,
}: TMenuPathItem) => {
  const { path, title } = menuItem;
  return (
    <SMenuItemWrapper
      hasSubMenu={hasSubMenu}
      onClick={() => handleToggle(false)}
    >
      <NavLink to={`${path}`}>{title}</NavLink>
    </SMenuItemWrapper>
  );
};

export default MenuPathItem;
