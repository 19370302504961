import { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import useForm from 'hooks/useForm';
import { EText } from 'data/datas/genericData';
import { RoutesPaths } from 'routes/Routes.types';
import {
  useConvertAnimalToSAMutation,
  useUpdateAnimalToSAConversionMutation,
} from 'redux/apiSlice/animal.slice';
import { EAnimalTypesUI, TConvertAnimalToSaUI } from 'data/types/animal.types';
import toast from 'components/Base/Notification';
import Modal, { ModalWidthEnum } from 'components/Base/Modal';
import { Paragraph, Title } from 'components/Base/Typography';
import { TSADocumentationModalProps } from './SADocumentationModal.types';
import SADocumentationForm from './SADocumentationForm';

const SADocumentationModal = forwardRef(
  (
    {
      handleNotificationEvent,
      conversion,
      afterClose = () => {
        // do nothing
      },
    }: TSADocumentationModalProps,
    ref,
  ) => {
    const navigate = useNavigate();
    const [convertToSA, { isLoading: convertToSALoading }] =
      useConvertAnimalToSAMutation();
    const [update, { isLoading: updateLoading }] =
      useUpdateAnimalToSAConversionMutation();
    const [openCancelConversionModal, setOpenCancelConversionModal] =
      useState(false);
    const [open, setOpen] = useState(false);
    const [animalId, setAnimalId] = useState('');
    const { form } = useForm<TConvertAnimalToSaUI>();
    useImperativeHandle(ref, () => ({
      open: (id: string) => {
        setOpen(true);
        setAnimalId(id);
      },
    }));
    const convertAnimalToSa = async (values: TConvertAnimalToSaUI) => {
      try {
        if (conversion?.id) {
          await update({
            conversionId: conversion?.id,
            ...values,
            animalId,
          } as TConvertAnimalToSaUI).unwrap();
        } else {
          await convertToSA({
            ...values,
            animalId,
          } as TConvertAnimalToSaUI).unwrap();
        }
        if (values.observableDisability) {
          toast({
            description: `Your conversion request has been successfully ${
              conversion?.id ? 'updated' : 'sent'
            }`,
            type: 'success',
          });
        }
      } catch (err) {
        const message = get(err, 'data.message', EText.DEFAULT_ERROR) as string;
        toast({
          description: message,
          type: 'error',
        });
      }
    };
    const handleSubmit = async () => {
      const values = await form.validateFields();
      if (!values.requiredBecauseOfDisability) {
        await convertAnimalToSa({
          requiredBecauseOfDisability: false,
          animalId,
        } as TConvertAnimalToSaUI);
        setOpen(false);
        setOpenCancelConversionModal(true);
        return;
      }

      if (values.observableDisability) {
        await convertAnimalToSa({
          requiredBecauseOfDisability: true,
          trainedFor: values.trainedFor,
          animalId,
          observableDisability: true,
        } as TConvertAnimalToSaUI);
        setOpen(false);
        return;
      }

      navigate(
        `/app/${RoutesPaths.ANIMAL}/${RoutesPaths.CONVERSION}?type=${EAnimalTypesUI.SA}&id=${animalId}`,
        {
          state: {
            trainedFor: values.trainedFor,
            conversionId: conversion?.id,
          },
        },
      );
      if (handleNotificationEvent) {
        handleNotificationEvent();
      }
    };

    const handleAfterClose = () => {
      setOpen(false);
      afterClose();
      if (handleNotificationEvent) {
        handleNotificationEvent();
      }
    };

    return (
      <>
        <Modal
          destroyOnClose
          visible={openCancelConversionModal}
          onCancel={() => setOpenCancelConversionModal(false)}
          footer={null}
          size={ModalWidthEnum.Small}
        >
          <Title className="mb-6 text-center" fontLevel={3}>
            Your conversion request cannot be processed.
          </Title>
          <Paragraph fontLevel={4} className="text-center">
            {conversion?.id
              ? 'The conversion request will not be approved since the animal has to be required for a disability to be considered an assistance animal.'
              : 'The animal has to be required for a disability to be considered an assistance animal.'}
          </Paragraph>
        </Modal>
        <Modal
          title="Service animal documentation"
          size={ModalWidthEnum.Medium}
          visible={open}
          onOk={handleSubmit}
          destroyOnClose
          afterClose={() => form.resetFields()}
          okText="Submit"
          confirmLoading={convertToSALoading || updateLoading}
          onCancel={() => handleAfterClose()}
        >
          <SADocumentationForm form={form} conversion={conversion} />
        </Modal>
      </>
    );
  },
);

export default SADocumentationModal;
