import { Outlet, useLocation } from 'react-router-dom';
import KnowledgeBaseAction from 'components/Shared/KnowledgeBaseAction';
import useDeviceType from 'hooks/useDeviceType';
import { RoutesPaths } from 'routes/Routes.types';
import { SLayoutWrapper, SMainContentWrapper } from './Layout.styles';
import Header from './Header';
import Notifications from './Notifications';

const Layout = () => {
  const location = useLocation();
  const { isMobile } = useDeviceType();

  const isMainSectionFullWith =
    location.pathname === `/app/${RoutesPaths.HOME}` && !isMobile;

  return (
    <SLayoutWrapper>
      <KnowledgeBaseAction />
      <Header />
      <Notifications />
      <SMainContentWrapper
        className={isMainSectionFullWith ? '' : 'container-lg'}
        fullwith={isMainSectionFullWith}
      >
        <Outlet />
      </SMainContentWrapper>
    </SLayoutWrapper>
  );
};

export default Layout;
