import styled from 'styled-components';
import { Header } from '@landlord-tech/ui-kit-opp/dist/components/Layout';
import { Colors, Screens } from 'core/CssVariables';
import { Icon } from 'components/Base/Icon';

export const SHeaderWrapper = styled(Header)`
  &.ant-layout-header {
    /* position: fixed; */
    width: 100%;
    /* z-index: 9; */
    background-color: ${Colors.PrimaryColor};
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.13);
    padding: 0 16px;

    @media (min-width: ${Screens.ScreensXS}) {
      padding: 0 50px;
    }
  }
`;

export const SLogoIcon = styled(Icon)`
  height: 40px;
`;

export const SCloseIcon = styled(Icon)`
  box-shadow: 0px 0px 12px rgb(0 0 0 / 10%);
  border-radius: 48px;
`;
