import { FC } from 'react';
import Button, { EButtonColors } from 'components/Base/Button';
import { TButtonProps } from 'components/Base/Button/type';
import { Text } from 'components/Base/Typography';

type TNotificationButtonProps = Omit<TButtonProps, 'type' | 'color' | 'size'>;

export const NotificationPrimaryButton: FC<TNotificationButtonProps> =
  props => {
    const { children, ...otherProps } = props;
    return (
      <Button
        type="primary"
        color={EButtonColors.Warning}
        size="small"
        {...otherProps}
      >
        <Text fontLevel={5}>{children}</Text>
      </Button>
    );
  };
