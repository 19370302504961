import { ToolTip } from '@landlord-tech/ui-kit-opp/dist/components/ToolTip';
import styled from 'styled-components';

import { TToolTipProps } from './ToolTip.types';

const SToolTip = styled(ToolTip)<TToolTipProps>`
  &&& {
  }
`;
export default SToolTip;
