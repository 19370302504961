import { useEffect } from 'react';
import { Paragraph } from 'components/Base/Typography';
import { Spinner } from 'components/Base/Spinner';
import { useGetAnimalByIdQuery } from 'redux/apiSlice/animal.slice';
import { Colors, FontWeights } from 'core/CssVariables';
import AnimalForm from './components/AnimalForm';
import { TCreateAnimalFormProps } from './CreateAnimalForm.types';

const CreateAnimalForm = ({
  animalId,
  formObj,
  actionType,
  animalType,
  hasActiveResidency,
  residencyMoveInDate,
}: TCreateAnimalFormProps) => {
  const { form } = formObj;
  const {
    data: animalData,
    isLoading,
    isSuccess,
  } = useGetAnimalByIdQuery(animalId, {
    skip: !animalId,
  });

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <div style={isLoading ? { height: '100vh' } : {}}>
      {animalId && (isLoading || !animalData) ? (
        <Spinner size="default" centered width={40} />
      ) : (
        <>
          <Paragraph
            fontWeight={FontWeights.Medium}
            color={Colors.Black}
            className="mb-2"
          >
            Upload animal picture
          </Paragraph>
          <AnimalForm
            animalType={animalData?.type?.value || animalType}
            animalData={animalData}
            actionType={actionType}
            formObj={formObj}
            hasActiveResidency={hasActiveResidency}
            residencyMoveInDate={residencyMoveInDate}
          />
        </>
      )}
    </div>
  );
};

export default CreateAnimalForm;
