import { FontWeights } from 'core/CssVariables';
import { Text } from 'components/Base/Typography';
import { useRef } from 'react';
import CreateAnimalModal from 'pages/private/Animal/components/CreateAnimal/CreateAnimalModal';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import appSlice from 'redux/slices/app.slice';
import { TCompleteAnimalBarProps } from './CompleteAnimalBar.types';
import NotificationItem from '../components/NotificationItem';
import { NotificationPrimaryButton } from '../components/NotificationButton';

const CompleteAnimalBar = ({ data }: TCompleteAnimalBarProps) => {
  const navigate = useNavigate();
  const createAnimalModalRef = useRef<{
    open: (id: string, actionType: string, url: string) => void;
  }>({
    open: () => {
      /* do nothing */
    },
  });
  const dispatch = useAppDispatch();

  if (!data || !data.count) return null;
  const { count, animalId, applicationId, incompleteResource } = data;
  const url = `/app/application/application-complete/${applicationId}`;
  const onCLick = () => {
    if (incompleteResource === 'ANIMAL') {
      createAnimalModalRef.current.open(
        animalId,
        'complete',
        applicationId ? url : '',
      );
      return;
    }
    if (applicationId) {
      dispatch(
        appSlice.actions.setViewApplicationProps({
          animalId,
        }),
      );
      navigate(url);
    }
  };

  return (
    <>
      <CreateAnimalModal ref={createAnimalModalRef} />
      <NotificationItem
        textSection={
          <Text fontWeight={FontWeights.SemiBold} ellipsis>
            {count > 1
              ? `There are ${count} animals that  need to be completed:`
              : `There is an animal that needs to be completed:`}
          </Text>
        }
        actionSection={
          <NotificationPrimaryButton onClick={onCLick}>
            {count > 1 ? 'Complete Animals' : 'Complete Animal'}
          </NotificationPrimaryButton>
        }
      />
    </>
  );
};

export default CompleteAnimalBar;
