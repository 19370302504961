import { useEffect } from 'react';
import { yesNoTrueFalseOptions } from 'data/datas/genericData';
import Form, { FormItem } from 'components/Base/Form';
import { Text } from 'components/Base/Typography';
import { RadioGroup } from 'components/Base/Radio';
import { hasESADocumentationFormFields } from './HasESADocumentationFrom.data';
import { THasESADocumentationFromProps } from './HasESADocumentationFrom.types';
import { SRadio } from './HasESADocumentationFrom.styles';

const HasESADocumentationFrom = ({
  conversion,
  form,
}: THasESADocumentationFromProps) => {
  const { setFieldsValue } = form;
  const { requiredBecauseOfDisability } = hasESADocumentationFormFields;

  useEffect(() => {
    if (!conversion) {
      return;
    }
    setFieldsValue({
      requiredBecauseOfDisability: true,
    });
  }, [conversion]);

  return (
    <Form
      form={form}
      name="esa-documentation-form"
      autoComplete="off"
      requiredMark={false}
      id="esa-documentation-form"
      scrollToFirstError
    >
      <Text fontLevel={5}>Is the animal required because of a disability?</Text>
      <FormItem
        {...requiredBecauseOfDisability}
        rules={[
          {
            type: 'boolean',
            required: true,
            message: 'This field is required!',
          },
        ]}
        className="mb-4 mb-0"
      >
        <RadioGroup>
          {yesNoTrueFalseOptions.map(({ value, label }) => (
            <SRadio value={value} label={label} key={label} />
          ))}
        </RadioGroup>
      </FormItem>
    </Form>
  );
};

export default HasESADocumentationFrom;
