import React, { FC } from 'react';
import useDeviceType from 'hooks/useDeviceType';
import { ButtonDropdown as UIKitButtonDropdown } from '@landlord-tech/ui-kit-opp/dist/components/ButtonDropdown';
import TButtonDropdownProps from './ButtonDropdown.types';

const ButtonDropdown: FC<TButtonDropdownProps> = props => {
  const { isMobile } = useDeviceType();

  return <UIKitButtonDropdown isMobile={isMobile} {...props} />;
};

export default ButtonDropdown;
