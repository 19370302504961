import { validationRules } from 'components/Base/Form';
import { Rule } from 'components/Base/Form/Form.types';

export const hasSADocumentationFormFields = {
  observableDisability: {
    name: 'observableDisability',
    rules: [
      { type: 'boolean', required: true, message: 'This field is required!' },
    ] as Rule[],
  },
  trainedFor: {
    name: 'trainedFor',
    label: 'What work or task has the animal been trained to perform?',
    rules: [validationRules.required()],
  },
  requiredBecauseOfDisability: {
    name: 'requiredBecauseOfDisability',
    rules: [
      { type: 'boolean', required: true, message: 'This field is required!' },
    ] as Rule[],
  },
};
