import { useMediaQuery } from 'react-responsive';
import { useMemo } from 'react';
import { Screens } from 'core/CssVariables';

const useDeviceType = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${Screens.ScreensMD})`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${Screens.ScreensMD}) and (max-width: 1023px)`,
  });
  const isDesktop = useMediaQuery({ query: `(min-width: 1024px)` });
  const isMobileSmall = useMediaQuery({
    query: `(max-width: ${Screens.ScreensXS})`,
  });

  return useMemo(
    () => ({
      isMobile,
      isTablet,
      isDesktop,
      isMobileSmall,
    }),
    [isMobile, isTablet, isDesktop, isMobileSmall],
  );
};

export default useDeviceType;
