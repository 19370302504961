import { Moment } from 'moment';
import {
  EOnboardingSubStep,
  EOnboardingStepUI,
} from 'pages/public/Onboarding_Lite/OnboardingStepper/OnboardingStepper.types';
import {
  TLeasePacketPropertyUi,
  TLeasePacketUnitUi,
} from './application.types';
import {
  EAnimalTypes,
  EAnimalTypesUI,
  TAnimalApplicationDataDB,
  TAnimalApplicationDataUI,
  TAnimalIncompleteUI,
} from './animal.types';
import {
  EApplicationStatuses,
  EApplicationStatusesUi,
  TPredefinedOptionType,
  TPredefinedOptionTypeUi,
} from './generalTypes';
import {
  ETenantResidencyStatus,
  ETenantResidencyStatusUI,
} from './tenant.types';

export type TExistingAnimalsDB = {
  animalId: string;
  applicationId: string | null;
  name: string;
  type: EAnimalTypes;
  selected: boolean;
  moveInDate?: string;
  hasApplication?: boolean;
};
export type TExistingAnimalsUI = {
  id: string;
  applicationId: string | null;
  name: string;
  type: EAnimalTypesUI;
};

export enum EOnboardingStepDB {
  RESIDENCY_FORM_CREATE = 'RESIDENCY_FORM_CREATE',
  RESIDENCY_FORM_COMPLETE = 'RESIDENCY_FORM_COMPLETE',
  RESIDENCY_ANIMAL_COUNT = 'RESIDENCY_ANIMAL_COUNT',
  RESIDENCY_ANIMAL_CREATE = 'RESIDENCY_ANIMAL_CREATE',
  RESIDENCY_ANIMAL_COMPLETE = 'RESIDENCY_ANIMAL_COMPLETE',
  PAYMENT = 'PAYMENT',
}

export type TResidencyData = {
  id: string;
  leaseToDate: string;
  leaseNextDate?: string;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  hasAnimal: boolean;
  animals: TAnimalResidencyDataDB[];
  existingAnimals: TExistingAnimalsDB[];
  propertyManagementId: string;
  applicantAnimals: TApplicantAnimalsDB[];
  leasePacketId: string;
  propertyId: string;
  onboardingStateId: string;
  resident: boolean | null;
  animalCount: number | null;
  leasePacketCode: string | null;
  roommateAnimalCount: number | null;
  roommateHasAnimal: boolean | null;
  step: EOnboardingStepDB;
  isPaymentOn: boolean;
};

export type TResidencyDataUI = {
  id: string;
  leaseToDate?: string;
  moveInDate?: string;
  leaseNextDate?: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  hasAnimal?: boolean;
  animalResidencies: TAnimalResidencyDataUI[];
  existingAnimals: TAnimalIncompleteUI[];
  propertyManagementId: string;
  applicantAnimals: TApplicantAnimalsUI[];
  leasePacketId: string;
  propertyId: string;
  onboardingStateId: string;
  status: EApplicationTypeUI | null;
  animalCount: number | null;
  leasePacketCode: string | null;
  roommateAnimalCount: number | null;
  roommateHasAnimal: boolean | null;
  step: EOnboardingStepUI;
  subStep?: EOnboardingSubStep;
  isPaymentOn: boolean;
};

export type TCompleteAnimalDetialUI = {
  animalId: string;
  moveIn: string;
};
export type TCompleteAnimalDetialDB = {
  animalId: string;
  moveIn?: string;
  reason?: string;
};

export enum EApplicationTypeDB {
  RESIDENT = 'RESIDENT',
  APPLICANT = 'APPLICANT',
}

export enum EApplicationTypeUI {
  RESIDENT = 'Resident',
  APPLICANT = 'Applicant',
}

export type TCreateTenantResidencyDB = {
  state: EApplicationTypeDB;
  leasePacketId: string;
  propertyName: string;
  unitName: string;
  moveIn: string;
  leaseTo: string;
  hasAnimal?: boolean;
  nextLeaseTo?: string;
  unitId?: string;
  roommateHasAnimal?: boolean;
  animalDetails: TCompleteAnimalDetialDB[] | null;
  animalCount?: number;
  roommateAnimalCount?: number;
};

export type TCreateTenantResidencyUI = {
  state: EApplicationTypeUI;
  leasePacketId: string;
  propertyName: { label: string; value: TLeasePacketPropertyUi };
  unit: { label: string; value: TLeasePacketUnitUi };
  startTime: Moment;
  endTime: Moment;
  secondEndTime?: Moment;
  haveAnimal?: boolean;
  roommateHasAnimal?: boolean;
  animals?: TAnimalIncompleteUI[];
  existingAnimals?: TAnimalIncompleteUI[];
  animalCount?: number;
  roommateAnimalCount?: number;
};

export type TCompleteTenantResidencyUI = {
  residencyId: string;
  haveAnimal: boolean;
  roommateHasAnimal: boolean;
  animals: TAnimalIncompleteUI[];
  existingAnimalList: TAnimalIncompleteUI[];
  unitNumber?: string;
  leaseToDate?: string;
  moveInDate?: string;
  applicantAnimals: TApplicantAnimalsUI[];
};
export type TCompleteTenantResidencyDB = {
  residencyId: string;
  hasAnimal: boolean;
  roommateHasAnimal: boolean;
  animalDetails: TCompleteAnimalDetialDB[];
  unitNumber?: string;
  leaseTo?: string;
  moveIn?: string;
};

export enum EAnimalResidencyStatusUI {
  ACTIVE = 'Moved In',
  TERMINATED = 'Moved out',
}

export enum EAnimalResidencyStatusDB {
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
}

export enum EResidencyStatusUI {
  RESIDENT = 'Resident',
  APPLICANT = 'Applicant',
  FORMER_RESIDENT = 'Former resident',
  DENIED = 'Not Approved',
}

export enum EResidencyStatusDB {
  RESIDENT = 'RESIDENT',
  APPLICANT = 'APPLICANT',
  FORMER_RESIDENT = 'FORMER_RESIDENT',
  DENIED = 'DENIED',
}

export type TAnimalResidencyDataUI = {
  id: string;
  name: string;
  status: EAnimalResidencyStatusUI;
  type: EAnimalTypesUI;
  typeConfirmed: boolean;
  moveInDate?: string;
};

export type TAnimalResidencyDataDB = {
  animalId: string;
  name: string;
  status: EAnimalResidencyStatusDB;
  type: EAnimalTypes;
  typeConfirmed: boolean;
  moveInDate?: string;
};
export enum EAnimalApplicationStatusUI {
  PENDING = 'Pending',
  NOT_APPROVED = 'Not Approved',
  RETRACTED = 'Retracted',
  APPROVED = 'Approved',
}

export enum EAnimalApplicationStatusDB {
  PENDING = 'PENDING',
  NOT_APPROVED = 'NOT_APPROVED',
  RETRACTED = 'RETRACTED',
  APPROVED = 'APPROVED',
}

export type TUpdateResidencyDataDB = {
  id: string;
  unitNumber: string;
  moveInDate: string;
  leaseToDate: string;
};
export type TUpdateResidencyDataUI = {
  id: string;
  unitNumber: string;
  moveInDate: string;
  leaseToDate: string;
};

export type TValidateResidencyUnitDB = {
  unitNumber: string;
  residencyApplicationId: string;
  propertyName: string;
};
export type TValidateResidencyUnitUI = {
  unitNumber: string;
  residencyApplicationId: string;
  propertyName: string;
};

export type TChangeResidencyFormDataDB = {
  id: string;
  unitNumber: string;
  moveInDate: string;
  leaseToDate: string;
  propertyName?: string;
};
export type TChangeResidencyFormDataUI = {
  propertyName?: string;
  id: string;
  unitNumber: { value?: string; label: string };
  moveInDate: Moment;
  leaseToDate: Moment;
};

export type TApplicantAnimalsDB = {
  animalId: string;
  name: string;
  type: EAnimalTypes;
  status: EApplicationStatuses;
  applicationId: string;
  typeConfirmed?: boolean;
  moveInDate?: string;
};
export type TApplicantAnimalsUI = {
  id: string;
  name: string;
  type: EAnimalTypesUI;
  status: EApplicationStatusesUi;
  applicationId: string;
  typeConfirmed?: boolean;
  moveInDate?: string;
};

export type TResidencyApplicationDataDB = {
  id: string;
  leaseToDate: string;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  hasAnimal?: boolean;
  applicantAnimals: TApplicantAnimalsDB[];
  existingAnimals: TExistingAnimalsDB[];
  propertyManagementId: string;
  roommateHasAnimal: boolean;
  leasePacketId: string;
};

export type TResidencyApplicationDataUI = {
  id: string;
  leaseToDate: string;
  moveInDate: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  unitNumber: string;
  hasAnimal?: boolean;
  applicantAnimals: TApplicantAnimalsUI[];
  existingAnimals: TAnimalIncompleteUI[];
  propertyManagementId: string;
  roommateHasAnimal: boolean;
  leasePacketId: string;
};

export type TCompleteResidencyApplicationDB = {
  residencyApplicationId: string;
  hasAnimal: boolean;
  roommateHasAnimal: boolean;
  animalIds: string[];
};
export type TCompleteResidencyApplicationUI = {
  residencyApplicationId: string;
  unitNumber?: string;
  moveInDate?: string;
  leaseToDate?: string;
  haveAnimal: boolean;
  roommateHasAnimal: boolean;
  animals: TAnimalIncompleteUI[];
  existingAnimals: TAnimalIncompleteUI[];
};

export type TSetApplicantsMoveInDateDB = {
  applicationId: string;
  moveInDate: string;
};

export type TSetApplicantsMoveInDateUI = {
  id: string;
  moveInDate: Moment;
};

export type TResidencyAnimalCountUI = {
  residencyId: string;
  animalCount: number | null;
  animalCountChangeReason?: string;
  roommateAnimalCount: number | null;
  roommateAnimalCountChangeReason?: string;
};
export type TResidencyAnimalCountDB = {
  residencyId: string;
  animalCount: number;
  animalCountChangeReason?: string;
  roommateAnimalCount: number;
  roommateAnimalCountChangeReason?: string;
};

export type TResidencyAnimalInfoDB = {
  breed?: TPredefinedOptionType;
  id: string;
  name: string;
  profilePicture?: string;
  type: EAnimalTypes;
  specie?: TPredefinedOptionType;
  complete: boolean;
};
export type TResidencyAnimalInfoUI = {
  breed?: TPredefinedOptionTypeUi;
  id: string;
  name: string;
  profilePicture?: string;
  type: EAnimalTypesUI;
  specie?: TPredefinedOptionTypeUi;
  complete: boolean;
};

export type TResidencyAnimalUI = TAnimalApplicationDataUI & {
  animal: TResidencyAnimalInfoUI;
};
export type TResidencyAnimalDB = TAnimalApplicationDataDB & {
  animal: TResidencyAnimalInfoDB;
};

export type TResidencyWithAnimalsDataUI = {
  id: string;
  leaseToDate?: string;
  moveInDate?: string;
  nextLeaseToDate?: string;
  animalResidencyId: string;
  propertyManagementLogo: string;
  propertyManagementName: string;
  propertyName: string;
  roommateAnimalCount: number;
  status: ETenantResidencyStatusUI;
  statusReason?: string;
  unitNumber: string;
  animals: TResidencyAnimalUI[];
  active: boolean;

  propertyId: string;
  unitId: string | null;
  leasePacketId?: string;
  canEdit: boolean;
};

export type TResidencyWithAnimalsDataDB = {
  id: string;
  animalResidencyId: string;
  status: ETenantResidencyStatus;
  propertyManagementName: string;
  propertyManagementLogo: string;
  propertyName: string;
  unitNumber: string;
  moveInDate?: string;
  leaseToDate?: string;
  nextLeaseToDate?: string;
  animals: TResidencyAnimalDB[];
  roommateAnimalCount: number;
  statusReason?: string;
  active: boolean;

  propertyId: string;
  unitId: string | null;
  leasePacketId?: string;
  editAllowed: boolean;
};
