import { FC } from 'react';
import { Col } from 'components/Base/Grid';
import {
  SNotificationRow,
  SNotificationRowContent,
} from './NotificationItem.styles';

type TNotificationItemProps = {
  hasTopBorder?: boolean;
  actionSection?: React.ReactNode;
  textSection?: React.ReactNode;
};

const NotificationItem: FC<TNotificationItemProps> = ({
  actionSection,
  textSection,
  hasTopBorder,
}) => {
  return (
    <SNotificationRow
      justify="center"
      align="middle"
      hasTopBorder={hasTopBorder}
    >
      <SNotificationRowContent align="middle" gutter={[8, 0]}>
        <Col>{textSection}</Col>
        <Col>{actionSection}</Col>
      </SNotificationRowContent>
    </SNotificationRow>
  );
};

export default NotificationItem;
