import pick from 'lodash/pick';
import omit from 'lodash/omit';
import moment from 'moment';
import { formattedDate } from 'helpers/utils';
import {
  TAuthConverter,
  TConvertor,
  TCreateConverter,
  TDeleteConverter,
  TFetchConverter,
} from 'data/types/convertor.types';
import {
  EAnimalActionDB,
  EAnimalActionUI,
  EAnimalStatusUponDeletionDB,
  EAnimalStatusUponDeletionUI,
  EAnimalTypes,
  EAnimalTypesUI,
  EAuthorizationStatus,
  EAuthorizationStatusUI,
  EConvertAnimalStatus,
  EConvertAnimalStatusUI,
  EMoveOutOrRetractReasonTypeDB,
  EMoveOutOrRetractReasonTypeUI,
  EResidencyTypeDB,
  EResidencyTypeUI,
  TAnimalAggressionUpdateDB,
  TAnimalAggressionUpdateUI,
  TAnimalAggressiveBehaviorDB,
  TAnimalAggressiveBehaviorUI,
  TAnimalApplicationDataDB,
  TAnimalApplicationDataUI,
  TAnimalBirthDate,
  TAnimalBirthDateUi,
  TAnimalBreedUpdate,
  TAnimalBreedUpdateUi,
  TAnimalConversionToPetDB,
  TAnimalConversionToPetUI,
  TAnimalCreateReturnedDataDB,
  TAnimalCreateReturnedDataUI,
  TAnimalDetails,
  TAnimalDetailsById,
  TAnimalDetailsByIdUi,
  TAnimalDetailsGenderField,
  TAnimalDetailsGenderFieldUi,
  TAnimalDetailsUi,
  TAnimalFormData,
  TAnimalFormDataUI,
  TAnimalGenderUpdate,
  TAnimalGenderUpdateUi,
  TAnimalGet,
  TAnimalHouseTrainedUpdate,
  TAnimalHouseTrainedUpdateUi,
  TAnimalIncompleteUI,
  TAnimalMetadata,
  TAnimalMetadataUi,
  TAnimalNameUpdate,
  TAnimalNameUpdateUi,
  TAnimalPostUI,
  TAnimalPrimaryColorUpdate,
  TAnimalPrimaryColorUpdateUi,
  TAnimalProfileUpdate,
  TAnimalProfileUpdateUi,
  TAnimalReproductiveUpdate,
  TAnimalReproductiveUpdateUi,
  TAnimalSecondaryColorUpdate,
  TAnimalSecondaryColorUpdateUi,
  TAnimalSpeciesUpdate,
  TAnimalSpeciesUpdateUi,
  TAnimalTrainedForUpdateUi,
  TAnimalTrainedForUpdate,
  TAnimalType,
  TAnimalTypeConversion,
  TAnimalTypeConversionUi,
  TAnimalTypeUI,
  TAnimalVetRecordCreate,
  TAnimalVetRecordCreateUi,
  TAnimalVetRecordsList,
  TAnimalVetRecordsListUi,
  TApplicationAnimal,
  TApplicationAnimalUI,
  TApplicationSADocPacketGet,
  TApplicationSADocPacketGetUI,
  TApplicationVetRecords,
  TApplicationVetRecordsUI,
  TBreedType,
  TBreedTypeUi,
  TCompleteAnimalFormData,
  TCompleteAnimalFormDataUI,
  TConfirmPayment,
  TConfirmPaymentUi,
  TConvertAnimalToPet,
  TConvertAnimalToPetUI,
  TEditingWeightField,
  TEditingWeightFieldUi,
  TGender,
  TGenderUI,
  TMoveOutFormDB,
  TMoveOutFormUI,
  TUnAuthorizedResidencyDB,
  TUnAuthorizedResidencyUI,
  TVetRecord,
  TVetRecordExpirationUpdate,
  TVetRecordExpirationUpdateUI,
  TVetRecordTypeNameUpdate,
  TVetRecordTypeNameUpdateUI,
  TVetRecordUI,
  TAnimalWeightUpdateUi,
  TAnimalWeightUpdate,
  TAnimalMicrochipDB,
  TAnimalMicrochipUI,
  TBlockedActionUI,
  TBlockedAction,
  TVaccinationRecordUI,
  TNewVaccinationRecordDB,
  TVetRecordDocumentsUI,
  TVetRecordDocumentsDB,
  TVaccinationRecordDB,
  TMoveOutFromResidencyFormUI,
  TMoveOutFromResidencyFormDB,
  TConvertAnimalToEsa,
  TConvertAnimalToEsaUI,
  TConvertAnimalToSaUI,
  TConvertAnimalToSa,
  TUpdateAnimalConvHPDocsUI,
  TUpdateAnimalConvHPDocs,
  TIncompleteAnimalsDataUI,
  TDuplicateCheckUI,
  TDuplicateCheckDB,
  TDeleteAnimalUI,
  TDeleteAnimalDB,
  TConvertedToPetAcknowledgmentUI,
  TConvertedToPetAcknowledgmentDB,
  TIncompleteAnimalsDataDB,
  TDuplicationPreventionUI,
  TDuplicationPreventionDB,
  TConditionalDetailsUI,
  TConditionalDetailsDB,
} from 'data/types/animal.types';
import { TFile } from 'data/types/storage.types';
import {
  convertedToPetNoReasonText,
  EConversionReasonDB,
  EConversionReasonUI,
  EText,
  vaccinationData,
} from 'data/datas/genericData';
import {
  getSADocPacketConvertor,
  trainerInfoConvertor,
} from './animalTraining.convertors';
import {
  aggressiveBehaviorConverter,
  animalStatusConverter,
  applicationStatusConvertor,
  editingFieldConvertor,
  editingYesNoFieldConvertor,
  emailMaskConverter,
  failsConvertor,
  genderEnumConvertor,
  selectCustomOptionConverter,
  selectCustomSpeciesOptionConverter,
  selectOptionConverter,
  selectOptionPredefinedConverter,
  verificationStatusConverter,
  yesNoOptionConverter,
} from './general.converters';
import {
  EGender,
  EGenderValue,
  EReproductive,
  EValueEditingStates,
  EVetRecordTypeEnumDB,
  EVetRecordTypeEnumUI,
  TOptionType,
  TOptionTypeUi,
} from '../types/generalTypes';
import {
  getHPDocPacketsConvertor,
  hpContactInfoConvertor,
  hpDocTypeConvertor,
  hpVisitationConvertor,
} from './docPacket.convertors';
import { THPDocInfo } from '../types/docPacket.types';
import { TTrainerDocInfo } from '../types/animalTraining.types';

export const animalTypesConvertor: TConvertor<EAnimalTypesUI, EAnimalTypes> = {
  fromDb: data => {
    return EAnimalTypesUI[EAnimalTypes[data] as keyof typeof EAnimalTypesUI];
  },
  toDb: data => {
    return EAnimalTypes[EAnimalTypesUI[data] as keyof typeof EAnimalTypes];
  },
};

export const authorizationStatusConvertor: TFetchConverter<
  EAuthorizationStatusUI,
  EAuthorizationStatus
> = {
  fromDb: data => {
    const status: { [key in EAuthorizationStatus]: EAuthorizationStatusUI } = {
      [EAuthorizationStatus.AUTHORIZED]: EAuthorizationStatusUI.AUTHORIZED,
      [EAuthorizationStatus.UN_AUTHORIZED]:
        EAuthorizationStatusUI.UN_AUTHORIZED,
      [EAuthorizationStatus.NO_STATUS]: EAuthorizationStatusUI.NO_STATUS,
    };
    return status[data];
  },
};
export const residencyTypeConvertor: TConvertor<
  EResidencyTypeUI,
  EResidencyTypeDB
> = {
  fromDb: data => {
    const status: { [key in EResidencyTypeDB]: EResidencyTypeUI } = {
      [EResidencyTypeDB.RESIDENCY]: EResidencyTypeUI.RESIDENCY,
      [EResidencyTypeDB.APPLICATION]: EResidencyTypeUI.APPLICATION,
    };
    return status[data];
  },
  toDb: data => {
    const status: { [key in EResidencyTypeUI]: EResidencyTypeDB } = {
      [EResidencyTypeUI.RESIDENCY]: EResidencyTypeDB.RESIDENCY,
      [EResidencyTypeUI.APPLICATION]: EResidencyTypeDB.APPLICATION,
    };
    return status[data];
  },
};

export const animalConditionalDetailsConverter: TFetchConverter<
  TConditionalDetailsUI,
  TConditionalDetailsDB
> = {
  fromDb: data => {
    return {
      dueDate: data.dueDate,
      reason: data.reason,
    };
  },
};
export const animalApplicationDataConverter: TFetchConverter<
  TAnimalApplicationDataUI,
  TAnimalApplicationDataDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      canRetract: data.canRetract,
      canMoveOut: data.canMoveOut,
      residenceId: data.residenceId,
      residencyType: residencyTypeConvertor.fromDb(data.residencyType),
      creationTime: formattedDate(data.creationTime),
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : null,
      moveInDate: formattedDate(data.moveInDate),
      status: applicationStatusConvertor.fromDb(data.status),
      inactiveStatusReason: data.inactiveStatusReason || '',
      requireComplete: data.requireComplete,
      animalResidencyId: data.animalResidencyId || undefined,
      conditionalDetails: data.conditionalDetails
        ? animalConditionalDetailsConverter.fromDb(data.conditionalDetails)
        : null,
      canReapply: data.canReapply,
      reapplyRequireReason: data.reapplyRequireReason,
    };
  },
};
export const animalActionConverter: TConvertor<
  EAnimalActionUI,
  EAnimalActionDB
> = {
  fromDb: data => {
    const action: { [key in EAnimalActionDB]: EAnimalActionUI } = {
      [EAnimalActionDB.MOVE_OUT]: EAnimalActionUI.MOVE_OUT,
      [EAnimalActionDB.DELETE]: EAnimalActionUI.DELETE,
      [EAnimalActionDB.CONVERT]: EAnimalActionUI.CONVERT,
    };
    return action[data];
  },
  toDb: data => {
    const action: { [key in EAnimalActionUI]: EAnimalActionDB } = {
      [EAnimalActionUI.MOVE_OUT]: EAnimalActionDB.MOVE_OUT,
      [EAnimalActionUI.DELETE]: EAnimalActionDB.DELETE,
      [EAnimalActionUI.CONVERT]: EAnimalActionDB.CONVERT,
    };
    return action[data];
  },
};

export const animalBlockedActionConverter: TFetchConverter<
  TBlockedActionUI,
  TBlockedAction
> = {
  fromDb: data => ({
    animalAction: animalActionConverter.fromDb(data.animalAction),
    reason: data.reason,
  }),
};

export const reproductiveConvertor: TFetchConverter<EReproductive, EGender> = {
  fromDb: data => {
    const reproduction: {
      [key in EGender]: EReproductive;
    } = {
      [EGender.FEMALE]: EReproductive.SPAYED,
      [EGender.MALE]: EReproductive.NEUTERED,
    };
    return reproduction[data];
  },
};

export const animalConversionReason: TFetchConverter<
  EConversionReasonUI,
  EConversionReasonDB
> = {
  fromDb: data => {
    const reasons: {
      [key in EConversionReasonDB]: EConversionReasonUI;
    } = {
      [EConversionReasonDB.NOT_RECEIVED_DOCUMENTATION]:
        EConversionReasonUI.NOT_RECEIVED_DOCUMENTATION,
      [EConversionReasonDB.NOT_REQUIRED_TO_ALLEVIATE]:
        EConversionReasonUI.NOT_REQUIRED_TO_ALLEVIATE,
      [EConversionReasonDB.SEVERAL_ATTEMPTS_TO_VERIFY]:
        EConversionReasonUI.SEVERAL_ATTEMPTS_TO_VERIFY,
      [EConversionReasonDB.WAS_NOT_PROVIDED_BY_ITSELF]:
        EConversionReasonUI.WAS_NOT_PROVIDED_BY_ITSELF,
      [EConversionReasonDB.NOT_REQUIRED_FOR_DISABILITY]:
        EConversionReasonUI.NOT_REQUIRED_FOR_DISABILITY,
    };
    return reasons[data];
  },
};

export const animalConversionToPetConvertor: TFetchConverter<
  TAnimalConversionToPetUI,
  TAnimalConversionToPetDB
> = {
  fromDb: data => {
    const isPredefined = Object.values(EConversionReasonDB).includes(
      data.convertedToPetReason as EConversionReasonDB,
    );
    return {
      convertedToPetReason: isPredefined
        ? animalConversionReason.fromDb(
            data.convertedToPetReason as EConversionReasonDB,
          )
        : data.convertedToPetReason || convertedToPetNoReasonText,
      isConvertedToPet: data.isConvertedToPet,
      isConvertedToPetReasonViewed: data.isConvertedToPetReasonViewed,
      isReasonPredefined: isPredefined,
    };
  },
};

export const animalStatusUponDeletionConverter: TFetchConverter<
  EAnimalStatusUponDeletionUI,
  EAnimalStatusUponDeletionDB
> = {
  fromDb: data => {
    const action: {
      [key in EAnimalStatusUponDeletionDB]: EAnimalStatusUponDeletionUI;
    } = {
      [EAnimalStatusUponDeletionDB.APPLICANT]:
        EAnimalStatusUponDeletionUI.APPLICANT,
      [EAnimalStatusUponDeletionDB.RESIDENT]:
        EAnimalStatusUponDeletionUI.RESIDENT,
    };
    return action[data];
  },
};

export const animalConvertor: TFetchConverter<TAnimalPostUI, TAnimalGet> = {
  fromDb: data => {
    return {
      // applications: (data?.residencies || []).map(item =>
      //   animalApplicationDataConverter.fromDb(item),
      // ),
      verificationStatus: verificationStatusConverter.fromDb(
        data.verificationStatus,
      ),
      // statusUponDeletion: data.statusUponDeletion
      //   ? animalStatusUponDeletionConverter.fromDb(data.statusUponDeletion)
      //   : null,
      // authorizationStatus:
      //   data.authorization?.status &&
      //   authorizationStatusConvertor.fromDb(data.authorization.status),
      complete: data.complete,
      name: data.name,
      profilePicture: data.profilePicture,
      type: animalTypesConvertor.fromDb(data.type || EAnimalTypes.PET),
      gender: genderEnumConvertor.fromDb(data.gender),
      reproduction:
        data.isReproductive !== null
          ? reproductiveConvertor.fromDb(data.gender)
          : null,
      species: data.species?.value,
      id: data.id,
      breed: data.breed?.value,
      weight: data.weight?.value,
      // actions: (data.actions || [])?.map(action =>
      //   animalActionConverter.fromDb(action),
      // ),
      blockedActions: (data.blockedActions || [])?.map(action =>
        animalBlockedActionConverter.fromDb(action),
      ),
      conversion: animalConversionToPetConvertor.fromDb(data.conversion),
      hasActiveResidency: data.hasActiveResidency
        ? animalStatusUponDeletionConverter.fromDb(data.hasActiveResidency)
        : null,
    };
  },
};

export const animalsConvertor: TFetchConverter<
  TAnimalIncompleteUI,
  TAnimalGet
> = {
  fromDb: data => ({
    name: data.name || '',
    type: animalTypesConvertor.fromDb(data.type || EAnimalTypes.PET),
    ...(data.status && {
      status: animalStatusConverter.fromDb(data.status),
    }),
    selected: false, // TODO: check if this is needed
    id: data.id,
  }),
};

export const genderConvertor: TAuthConverter<TGenderUI, TGender, null, null> = {
  toDb: data => ({
    type: genderEnumConvertor.toDb(data.gender),
    isReproductive: yesNoOptionConverter.toDb(data.neutered),
  }),
  fromDb: () => null,
};

export const createAnimalConvertor: TAuthConverter<
  TAnimalFormDataUI,
  TAnimalFormData,
  TAnimalCreateReturnedDataUI,
  TAnimalCreateReturnedDataDB
> = {
  toDb: data => {
    return {
      animalType: animalTypesConvertor.toDb(data.animalType),
      name: data.name,
      birthDate: formattedDate(data.birthDate, 'YYYY-MM-DD'),
      breed: data.breed ? selectOptionConverter.toDb(data.breed) : null,
      species: selectOptionConverter.toDb(data.species),
      primaryColor: selectOptionConverter.toDb(data.primaryColor),
      secondaryColor1: data.secondaryColor1?.id || null,
      secondaryColor2: data.secondaryColor2?.id || null,
      weight: data.weight || null,
      profilePicture: data.profilePicture?.url,
      gender: genderConvertor.toDb(data),
      houseTrained: yesNoOptionConverter.toDb(data.houseTrained),
      ...(data.animalType !== EAnimalTypesUI.PET && {
        requiredBecauseOfDisability: data.requiredBecauseOfDisability,
      }),
      ...(data.animalType === EAnimalTypesUI.SA && {
        trainedFor: data.trainedFor,
      }),
      aggressiveBehavior: {
        acted: yesNoOptionConverter.toDb(data.isAggressive),
        incidentDetails: data.aggressionInfo,
      },
      microchip: data.microchip || null,
      moveInDate:
        data.moveInDate && formattedDate(data.moveInDate, 'YYYY-MM-DD'),
    };
  },
  fromDb: data => {
    return {
      id: data.animalId,
      isConvertedToPet: data.isConvertedToPet,
    };
  },
};

export const breedConverter: TCreateConverter<TBreedTypeUi, TBreedType> = {
  toDb: data => {
    if (data.id) {
      return selectOptionConverter.toDb(data);
    }
    return selectCustomOptionConverter.fromDb(data);
  },
};

export const completeAnimalConvertor: TAuthConverter<
  TCompleteAnimalFormDataUI,
  TCompleteAnimalFormData,
  string,
  string
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      name: data.name,
      animalType: data.type.value,
      birthDate: formattedDate(data.birthDate, 'YYYY-MM-DD'),
      species: !data.species.__isNew__
        ? selectOptionConverter.toDb(data.species)
        : selectCustomSpeciesOptionConverter.fromDb(data.species),
      breed: data.breed ? breedConverter.toDb(data.breed) : null,
      primaryColor: !data.primaryColor.__isNew__
        ? selectOptionConverter.toDb(data.primaryColor)
        : selectCustomOptionConverter.fromDb(data.primaryColor),
      secondaryColor1: data.secondaryColor1?.id || null,
      secondaryColor2: data.secondaryColor2?.id || null,
      weight: data.weight || null,
      profilePicture: (data.profilePicture as TFile)?.url
        ? (data.profilePicture as TFile).url
        : (data.profilePicture as string),
      gender: genderConvertor.toDb(data),
      ...(data.type.value !== EAnimalTypesUI.PET && {
        requiredBecauseOfDisability: data.requiredBecauseOfDisability,
      }),
      ...(data.type.value === EAnimalTypesUI.SA && {
        trainedFor: data.trainedFor,
      }),
      houseTrained: yesNoOptionConverter.toDb(data.houseTrained),
      aggressiveBehavior: {
        acted: yesNoOptionConverter.toDb(data.isAggressive),
        incidentDetails: data.aggressionInfo,
      },
      microchip: data.microchip,
    };
  },
  fromDb: convertedToPet => convertedToPet,
};

export const updateAnimalTypeConvertor: TCreateConverter<
  TAnimalTypeUI,
  TAnimalType
> = {
  toDb: data => ({
    animalId: data.animalId,
    newType: animalTypesConvertor.toDb(data.type),
  }),
};

export const deleteVetRecordConvertor: TDeleteConverter<
  TVetRecordUI,
  TVetRecord
> = {
  toDb: data => ({
    animalId: data.animalId,
    vetRecordId: data.vetRecordId,
  }),
};

export const vetRecordTypeUpdateConverter: TCreateConverter<
  TVetRecordTypeNameUpdateUI,
  TVetRecordTypeNameUpdate
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      vetRecordId: data.vetRecordId,
      type:
        data.type === 'Rabies'
          ? {
              predefined:
                EVetRecordTypeEnumDB[
                  data.type as keyof typeof EVetRecordTypeEnumDB
                ],
            }
          : { custom: data.type },
    };
  },
};

export const vetRecordExpirationUpdateConverter: TCreateConverter<
  TVetRecordExpirationUpdateUI,
  TVetRecordExpirationUpdate
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      vetRecordId: data.vetRecordId,
      expiration: {
        expirable: true,
        date: data.expirationDate,
      },
    };
  },
};

export const createVetRecordConvertor: TCreateConverter<
  TAnimalVetRecordCreateUi,
  TAnimalVetRecordCreate
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      records: data.records,
      files: data.files,
      docName: data.files[0].name,
    };
  },
};

export const confirmPaymentConvertor: TCreateConverter<
  TConfirmPaymentUi,
  TConfirmPayment
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      leasePacketId: data.leasePacketId,
      propertyName: data.propertyName,
    };
  },
};

export const getAnimalInfoListConvertor: TFetchConverter<
  TOptionTypeUi[],
  TOptionType[]
> = {
  fromDb: data => data?.map(item => selectOptionConverter.fromDb(item)) || [],
};

export const editingWeightFieldConvertor: TFetchConverter<
  TEditingWeightFieldUi,
  TEditingWeightField
> = {
  fromDb: data => {
    return {
      value: data?.value ? data.value.toString() : '',
      unit: data?.unit || 'LBS',
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
      canRemove: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.REMOVE].includes(item),
      ),
    };
  },
};

export const vetRecordUpdateConverter: TCreateConverter<
  TVaccinationRecordUI,
  TNewVaccinationRecordDB
> = {
  toDb: data => {
    return {
      type: vaccinationData.includes(data.type.value as string)
        ? {
            predefined:
              EVetRecordTypeEnumDB[
                data.type.value as keyof typeof EVetRecordTypeEnumDB
              ],
          }
        : { custom: data.type.value },
      expiration: data.expiration?.date
        ? {
            expired: data.expiration.expired,
            expirable: data.expiration.expirable,
            ...(data.expiration.expirable && {
              date: moment(data.expiration.date).format('YYYY-MM-DDTHH:mm:ss'),
            }),
          }
        : null,
      ...(!data.id?.includes('new') && { id: data.id }),
    };
  },
};

export const updateVaccinationRecordsConverter: TCreateConverter<
  TVetRecordDocumentsUI,
  TVetRecordDocumentsDB
> = {
  toDb(data) {
    return {
      animalId: data.animalId,
      vetRecordId: data.vetRecordId,
      newFiles: data.newFiles,
      removedDocumentsId: data.removedDocumentsId,
      removedRecordIds: data.removedRecordIds,
      newRecords: (data.newRecords || []).map(record =>
        vetRecordUpdateConverter.toDb(record),
      ),
      changedRecords: (data.changedRecords || []).map(record =>
        vetRecordUpdateConverter.toDb(record),
      ),
    };
  },
};

export const animalDetailsGenderConverter: TFetchConverter<
  TAnimalDetailsGenderFieldUi,
  TAnimalDetailsGenderField
> = {
  fromDb: data => {
    return {
      value: genderEnumConvertor.fromDb(data.value),
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
      canRemove: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.REMOVE].includes(item),
      ),
    };
  },
};
export const animalVetRecordTypeConvertor: TFetchConverter<
  TVaccinationRecordUI[],
  TVaccinationRecordDB[]
> = {
  fromDb(data) {
    return data.map(record => {
      if (record.type.predefined) {
        record.type.value =
          EVetRecordTypeEnumUI[
            record.type.value as keyof typeof EVetRecordTypeEnumUI
          ];
      }
      if (record.expiration) {
        record.expiration.date = !record.expiration.expirable
          ? EText.VACCINATION_OTHER_NO_EXPIRE
          : formattedDate(record.expiration.date);
      }
      return record;
    });
  },
};

export const animalVetRecordConvertor: TFetchConverter<
  TAnimalVetRecordsListUi,
  TAnimalVetRecordsList
> = {
  fromDb: data => {
    return {
      type: data.type,
      canEdit: data.locked,
      locked: data.locked,
      id: data.id,
      expiration: data.expiration,
      isExpired: data.IsExpired,
      vaccinationRecords: animalVetRecordTypeConvertor.fromDb(data.records),
      documentFiles: data.documentFiles,
      ...(data.verificationStatus && {
        status: verificationStatusConverter.fromDb(data.verificationStatus),
      }),
    };
  },
};

export const applicationSADocPacketConvertor: TFetchConverter<
  TApplicationSADocPacketGetUI,
  TApplicationSADocPacketGet
> = {
  fromDb: data => ({
    id: data.id,
    trainerInfo: trainerInfoConvertor.fromDb(data.trainerInfo),
    saLetters: data.saLetters,
    trainedFor: data.trainedFor,
    startTime: data.trainingStartDate,
    endTime: data.trainingEndDate,
    canEdit: data.modifiable,
    status: verificationStatusConverter.fromDb(data.verificationStatus),
    fails: failsConvertor.fromDb(data.fails || {}),
  }),
};

export const animalConversionStatusConvertor: TFetchConverter<
  EConvertAnimalStatusUI,
  EConvertAnimalStatus
> = {
  fromDb: data => {
    const statuses: {
      [key in EConvertAnimalStatus]: EConvertAnimalStatusUI;
    } = {
      [EConvertAnimalStatus.APPROVED]: EConvertAnimalStatusUI.APPROVED,
      [EConvertAnimalStatus.PENDING]: EConvertAnimalStatusUI.PENDING,
      [EConvertAnimalStatus.REJECTED]: EConvertAnimalStatusUI.REJECTED,
    };
    return statuses[data];
  },
};

export const animalTypeConversionListConvertor: TFetchConverter<
  TAnimalTypeConversionUi,
  TAnimalTypeConversion
> = {
  fromDb: data => {
    const isPredefined = Object.values(EConversionReasonDB).includes(
      data.reason as EConversionReasonDB,
    );
    return {
      createdAt: formattedDate(data.createdAt),
      fromType: animalTypesConvertor.fromDb(data.fromType),
      hpDocumentPacket: data.hpDocumentPacket
        ? getHPDocPacketsConvertor.fromDb(data.hpDocumentPacket)
        : null,
      saDocumentPacket: data.saDocumentPacket
        ? applicationSADocPacketConvertor.fromDb(data.saDocumentPacket)
        : null,
      id: data.id,
      reason:
        data.reason && isPredefined
          ? animalConversionReason.fromDb(data.reason as EConversionReasonDB)
          : data.reason,
      status: animalConversionStatusConvertor.fromDb(data.status),
      toType: animalTypesConvertor.fromDb(data.toType),
      trainedFor: data.trainedFor,
      observableDisability: data.observableDisability,
      hpDocumentInfoNotProvidedReason: data.hpDocumentInfoNotProvidedReason,
      trainerDocumentInfoNotProvidedReason:
        data.trainerDocumentInfoNotProvidedReason,
      isPredefinedReason: isPredefined,
    };
  },
};

export const animalDetailsConverter: TFetchConverter<
  TAnimalDetailsUi,
  TAnimalDetails
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: editingFieldConvertor.fromDb(data.name),
      profilePicture: editingFieldConvertor.fromDb(data.profilePicture),
      species: editingFieldConvertor.fromDb(data.species),
      breed: editingFieldConvertor.fromDb(data.breed),
      conversions:
        data.conversions?.map(item =>
          animalTypeConversionListConvertor.fromDb(item),
        ) || [],
      primaryColor: editingFieldConvertor.fromDb(data.primaryColor),
      secondaryColor1: editingFieldConvertor.fromDb(data.secondaryColor1),
      secondaryColor2: editingFieldConvertor.fromDb(data.secondaryColor2),
      birthDate: editingFieldConvertor.fromDb(data.birthDate),
      weight: editingWeightFieldConvertor.fromDb(data.weight),
      isHouseTrained: editingYesNoFieldConvertor.fromDb(data.isHouseTrained),
      gender: animalDetailsGenderConverter.fromDb(data.gender),
      isReproductive: editingYesNoFieldConvertor.fromDb(data.isReproductive),
      type: animalTypesConvertor.fromDb(data.type),
      ...(data.status && {
        status: verificationStatusConverter.fromDb(data.status),
      }),
      authorizationStatus:
        data.authorization?.status &&
        authorizationStatusConvertor.fromDb(data.authorization.status),
      vetRecordList:
        data.vetRecords?.map(item => animalVetRecordConvertor.fromDb(item)) ||
        [],

      packets: (data.saDocumentPackets || []).map(item =>
        getSADocPacketConvertor.fromDb(item),
      ),
      actions: (data.actions || [])?.map(action =>
        animalActionConverter.fromDb(action),
      ),
      blockedActions: (data.blockedActions || [])?.map(action =>
        animalBlockedActionConverter.fromDb(action),
      ),
      aggressiveBehavior: aggressiveBehaviorConverter.fromDb(
        data.aggressiveBehavior,
      ),
      confirmAggressiveBehavior: data.confirmAggressiveBehavior,
      trainedFor: data.trainedFor
        ? editingFieldConvertor.fromDb(data.trainedFor)
        : undefined,
      isRequiredBecauseOfDisability: data.isRequiredBecauseOfDisability,
      hasActiveResidency: data.hasActiveResidency
        ? animalStatusUponDeletionConverter.fromDb(data.hasActiveResidency)
        : null,
      microchip: data.microchip,
    };
  },
};

export const animalMetadataConverter: TFetchConverter<
  TAnimalMetadataUi,
  TAnimalMetadata
> = {
  fromDb: data => {
    return {
      description: data.description,
      hasEsaLetterForAnimal: yesNoOptionConverter.fromDb(
        data.hasEsaLetterForAnimal,
      ),
      trainedForObservableDisability: yesNoOptionConverter.fromDb(
        data.trainedForObservableDisability,
      ),
      trainedForObservableDisabilityDescription:
        data.trainedForObservableDisabilityDescription,
    };
  },
};

export const animalDetailsByIdConverter: TFetchConverter<
  TAnimalDetailsByIdUi,
  TAnimalDetailsById
> = {
  fromDb: data => {
    return {
      name: data.name?.value,
      profilePicture: data.profilePicture?.value,
      species: data.species?.value
        ? selectOptionPredefinedConverter.fromDb(data.species)
        : null,
      type: data.type
        ? {
            value: animalTypesConvertor.fromDb(data.type),
            label: animalTypesConvertor.fromDb(data.type),
          }
        : null,
      breed: data.breed?.value
        ? selectOptionPredefinedConverter.fromDb(data.breed)
        : null,
      primaryColor: data.primaryColor?.value
        ? selectOptionPredefinedConverter.fromDb(data.primaryColor)
        : null,
      secondaryColor1: data.secondaryColor1?.value
        ? selectOptionPredefinedConverter.fromDb(data.secondaryColor1)
        : null,
      secondaryColor2: data.secondaryColor2?.value
        ? selectOptionPredefinedConverter.fromDb(data.secondaryColor2)
        : null,
      birthDate: data.birthDate?.value ? moment(data.birthDate?.value) : null,
      weight: data.weight?.value,
      houseTrained: yesNoOptionConverter.fromDb(data.isHouseTrained?.value),
      gender: EGenderValue[data.gender?.value as keyof typeof EGenderValue],
      neutered: yesNoOptionConverter.fromDb(data.isReproductive?.value),
      trainedFor: data.trainedFor.value || '',
      microchip: data.microchip,
      aggressiveBehavior: aggressiveBehaviorConverter.fromDb(
        data.aggressiveBehavior,
      ),
    };
  },
};

export const animalBreedUpdateConverter: TCreateConverter<
  TAnimalBreedUpdateUi,
  TAnimalBreedUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      breed: selectOptionConverter.toDb(data.value),
    };
  },
};
export const animalSpeciesUpdateConverter: TCreateConverter<
  TAnimalSpeciesUpdateUi,
  TAnimalSpeciesUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      species: selectOptionConverter.toDb(data.value),
    };
  },
};
export const animalPrimaryColorUpdateConverter: TCreateConverter<
  TAnimalPrimaryColorUpdateUi,
  TAnimalPrimaryColorUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      primaryColor: selectOptionConverter.toDb(data.value),
    };
  },
};
export const animalSecondaryColorUpdateConverter: TCreateConverter<
  TAnimalSecondaryColorUpdateUi,
  TAnimalSecondaryColorUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      colorId: data.value,
    };
  },
};
export const animalAggressionBehavorConverter: TCreateConverter<
  TAnimalAggressiveBehaviorUI,
  TAnimalAggressiveBehaviorDB
> = {
  toDb: data => {
    return {
      acted: yesNoOptionConverter.toDb(data.acted),
      incidentDetails: data.incidentDetails || undefined,
    };
  },
};
export const animalAggressionUpdateConverter: TCreateConverter<
  TAnimalAggressionUpdateUI,
  TAnimalAggressionUpdateDB
> = {
  toDb: data => {
    return {
      animalId: data.id,
      aggressiveBehavior: animalAggressionBehavorConverter.toDb(
        data.aggressiveBehavior,
      ),
    };
  },
};

export const animalMicrochipUpdateConverter: TCreateConverter<
  TAnimalMicrochipUI,
  TAnimalMicrochipDB
> = {
  toDb: data => {
    return {
      animalId: data.id,
      microchip: data.microchip,
    };
  },
};

export const animalHouseTrainedUpdateConverter: TCreateConverter<
  TAnimalHouseTrainedUpdateUi,
  TAnimalHouseTrainedUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      houseTrained: yesNoOptionConverter.toDb(data.value),
    };
  },
};
export const animalNameUpdateConverter: TCreateConverter<
  TAnimalNameUpdateUi,
  TAnimalNameUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      name: data.value,
    };
  },
};
export const animalReproductiveUpdateConverter: TCreateConverter<
  TAnimalReproductiveUpdateUi,
  TAnimalReproductiveUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      reproductive: yesNoOptionConverter.toDb(data.value),
    };
  },
};
export const animalWeightUpdateConverter: TCreateConverter<
  TAnimalWeightUpdateUi,
  TAnimalWeightUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      weight: data.value || null,
      unit: 'LBS',
    };
  },
};
export const animalTrainedForUpdateConverter: TCreateConverter<
  TAnimalTrainedForUpdateUi,
  TAnimalTrainedForUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      trainedFor: data.value || '',
    };
  },
};

export const animalProfilePictureUpdateConverter: TCreateConverter<
  TAnimalProfileUpdateUi,
  TAnimalProfileUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      profilePicture: data.value,
    };
  },
};

export const animalGenderUpdateConverter: TCreateConverter<
  TAnimalGenderUpdateUi,
  TAnimalGenderUpdate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      gender: genderEnumConvertor.toDb(data.value),
    };
  },
};
export const animalBirthUpdateConverter: TCreateConverter<
  TAnimalBirthDateUi,
  TAnimalBirthDate
> = {
  toDb: data => {
    return {
      animalId: data.id,
      birthDate: formattedDate(data.value, 'YYYY-MM-DD'),
    };
  },
};

export const applicationAnimalVetRecordsConvertor: TFetchConverter<
  TApplicationVetRecordsUI[],
  TApplicationVetRecords[]
> = {
  fromDb: data =>
    data?.map(item => ({
      ...animalVetRecordConvertor.fromDb(item),
      fails: failsConvertor.fromDb(item.fails || {}),
    })) || [],
};

export const applicationAnimalConvertor: TFetchConverter<
  TApplicationAnimalUI,
  TApplicationAnimal
> = {
  fromDb: data => {
    const documentsFails = pick(data.fails || {}, [
      'saDocumentPackets',
      'vetRecords',
    ]);
    return {
      id: data.id,
      name: editingFieldConvertor.fromDb(data.name),
      profilePicture: editingFieldConvertor.fromDb(data.profilePicture),
      species: editingFieldConvertor.fromDb(data.species),
      breed: editingFieldConvertor.fromDb(data.breed),
      primaryColor: editingFieldConvertor.fromDb(data.primaryColor),
      secondaryColor1: editingFieldConvertor.fromDb(data.secondaryColor1),
      secondaryColor2: editingFieldConvertor.fromDb(data.secondaryColor2),
      birthDate: editingFieldConvertor.fromDb(data.birthDate),
      weight: editingWeightFieldConvertor.fromDb(data.weight),
      isHouseTrained: editingYesNoFieldConvertor.fromDb(data.isHouseTrained),
      gender: animalDetailsGenderConverter.fromDb(data.gender),
      isReproductive: editingYesNoFieldConvertor.fromDb(data.isReproductive),
      type: animalTypesConvertor.fromDb(data.type),
      ...(data.status && {
        status: verificationStatusConverter.fromDb(data.status),
      }),
      vetRecordList: applicationAnimalVetRecordsConvertor.fromDb(
        data.vetRecords || [],
      ),
      packets: (data.saDocumentPackets || []).map(item =>
        applicationSADocPacketConvertor.fromDb(item),
      ),
      fails: failsConvertor.fromDb(
        omit(data.fails || {}, ['saDocumentPackets', 'vetRecords']),
      ),
      documentsFails: failsConvertor.fromDb(documentsFails),
      aggressiveBehavior: aggressiveBehaviorConverter.fromDb(
        data.aggressiveBehavior,
      ),
      confirmAggressiveBehavior: data.confirmAggressiveBehavior,
      hasActiveResidency: data.hasActiveResidency
        ? animalStatusUponDeletionConverter.fromDb(data.hasActiveResidency)
        : null,
      trainedFor: data.trainedFor
        ? editingFieldConvertor.fromDb(data.trainedFor)
        : undefined,
      isRequiredBecauseOfDisability: data.isRequiredBecauseOfDisability,
      microchip: data.microchip,
    };
  },
};

export const moveOutOrRetractReasonTypeConverter: TConvertor<
  EMoveOutOrRetractReasonTypeUI,
  EMoveOutOrRetractReasonTypeDB
> = {
  fromDb: data => {
    const reason: {
      [key in EMoveOutOrRetractReasonTypeDB]: EMoveOutOrRetractReasonTypeUI;
    } = {
      [EMoveOutOrRetractReasonTypeDB.ANIMAL_HAS_PASSED_AWAY]:
        EMoveOutOrRetractReasonTypeUI.ANIMAL_HAS_PASSED_AWAY,
      [EMoveOutOrRetractReasonTypeDB.ANIMAL_HAS_RE_HOMED]:
        EMoveOutOrRetractReasonTypeUI.ANIMAL_HAS_RE_HOMED,
    };
    return reason[data];
  },
  toDb: data => {
    const reason: {
      [key in EMoveOutOrRetractReasonTypeUI]: EMoveOutOrRetractReasonTypeDB;
    } = {
      [EMoveOutOrRetractReasonTypeUI.ANIMAL_HAS_PASSED_AWAY]:
        EMoveOutOrRetractReasonTypeDB.ANIMAL_HAS_PASSED_AWAY,
      [EMoveOutOrRetractReasonTypeUI.ANIMAL_HAS_RE_HOMED]:
        EMoveOutOrRetractReasonTypeDB.ANIMAL_HAS_RE_HOMED,
    };
    return reason[data];
  },
};

export const animalMoveOutConvertor: TCreateConverter<
  TMoveOutFormUI,
  TMoveOutFormDB
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      reason: data.other
        ? { other: data.other }
        : { predefined: moveOutOrRetractReasonTypeConverter.toDb(data.reason) },
      ...(data.reason ===
        EMoveOutOrRetractReasonTypeUI.ANIMAL_HAS_PASSED_AWAY && {
        deleteAnimal: !!data.deleteAnimal,
      }),
    };
  },
};

export const animalMoveOutFromResidenciesConvertor: TCreateConverter<
  TMoveOutFromResidencyFormUI,
  TMoveOutFromResidencyFormDB
> = {
  toDb: data => {
    return {
      animalIds: data.animalIds,
      residencyId: data.residencyId,
      moveOutReason: data.other
        ? { other: data.other }
        : { predefined: moveOutOrRetractReasonTypeConverter.toDb(data.reason) },
      ...(data.reason ===
        EMoveOutOrRetractReasonTypeUI.ANIMAL_HAS_PASSED_AWAY && {
        deleteAnimal: !!data.deleteAnimal,
      }),
    };
  },
};

export const convertAnimalToPetConverter: TCreateConverter<
  TConvertAnimalToPetUI,
  TConvertAnimalToPet
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
    };
  },
};

export const convertAnimalToESAConverter: TCreateConverter<
  TConvertAnimalToEsaUI,
  TConvertAnimalToEsa
> = {
  toDb: data => {
    const hpDocInfo: THPDocInfo = {
      reason: data.hpReason || null,
      ...(data.hasHpDoc && {
        hpVisitation: data.websiteAdvertised
          ? hpVisitationConvertor.toDb({
              websiteAdvertised: data.websiteAdvertised,
              websites: data.websites,
            })
          : undefined,
        hpDocPacketLetter: data.hpDocPacketLetter,
        hpContactInfo:
          data.name || data.emailAddress || data.phoneNumber || data.faxNumber
            ? hpContactInfoConvertor.toDb({
                name: data.name,
                emailAddress: data.emailAddress,
                phoneNumber: data.phoneNumber,
                faxNumber: data.faxNumber,
              })
            : undefined,
        receivedAfterCompletingQuestionnaireOrInterview:
          data.receivedAfterCompletingQuestionnaireOrInterview === 'Yes',
      }),
    };

    return {
      conversionId: data.conversionId,
      ...(!data.cancelConversionRequest && {
        requiredBecauseOfDisability: data.requiredBecauseOfDisability,
      }),
      cancelConversionRequest: data.cancelConversionRequest || null,
      animalId: data.animalId,
      ...(data.requiredBecauseOfDisability &&
        !data.cancelConversionRequest && { hpDocInfo }),
    };
  },
};

export const convertAnimalToSAConverter: TCreateConverter<
  TConvertAnimalToSaUI,
  TConvertAnimalToSa
> = {
  toDb: data => {
    const trainerInfo = {
      name: data.name,
      emailAddress: data.emailAddress,
      faxNumber: data.faxNumber,
      phoneNumber: data.phoneNumber,
    };
    const hpDocInfo: THPDocInfo = {
      reason: data.hpReason || null,
      ...(data.trainedFor && { trainedFor: data.trainedFor }),
      ...(data.hasHpDoc && {
        hpVisitation: data.websiteAdvertised
          ? hpVisitationConvertor.toDb({
              websiteAdvertised: data.websiteAdvertised,
              websites: data.websites,
            })
          : undefined,
        hpDocPacketLetter: data.hpDocPacketLetter,
        hpContactInfo:
          data.hpDocFormName ||
          data.hpDocFormEmailAddress ||
          data.hpDocFormPhoneNumber ||
          data.hpDocFormFaxNumber
            ? hpContactInfoConvertor.toDb({
                name: data.hpDocFormName,
                emailAddress: data.hpDocFormEmailAddress,
                phoneNumber: data.hpDocFormPhoneNumber,
                faxNumber: data.hpDocFormFaxNumber,
              })
            : undefined,
        receivedAfterCompletingQuestionnaireOrInterview:
          data.receivedAfterCompletingQuestionnaireOrInterview === 'Yes',
      }),
    };

    const trainingDocInfo: TTrainerDocInfo = {
      reason: data.trainerReason || null,
      ...(data.hasTrainerDoc && {
        saLetters: data.saLetters,
        trainerInfo: trainerInfoConvertor.toDb(trainerInfo),
        trainingStartDate: data.startTime
          ? formattedDate(data.startTime, 'YYYY-MM-DD')
          : undefined,
        trainingEndDate: data.endTime
          ? formattedDate(data.endTime, 'YYYY-MM-DD')
          : undefined,
      }),
    };
    return {
      animalId: data.animalId,
      conversionId: data.conversionId,
      requiredBecauseOfDisability: data.requiredBecauseOfDisability,
      ...(data.requiredBecauseOfDisability && {
        trainedFor: data.trainedFor,
        observableDisability: data.observableDisability,
        ...(!data.observableDisability && { hpDocInfo, trainingDocInfo }),
      }),
    };
  },
};

export const updateAnimalConversionHPDocsConverter: TCreateConverter<
  TUpdateAnimalConvHPDocsUI,
  TUpdateAnimalConvHPDocs
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      conversionId: data.conversionId,
      hpContactInfo:
        data.name || data.emailAddress || data.phoneNumber || data.faxNumber
          ? hpContactInfoConvertor.toDb({
              name: data.name,
              emailAddress: data.emailAddress,
              phoneNumber: data.phoneNumber,
              faxNumber: data.faxNumber,
            })
          : undefined,
      hpDocumentPacketType: data.hpDocType
        ? hpDocTypeConvertor.toDb(data.hpDocType)
        : undefined,
      hpVisitation: data.websiteAdvertised
        ? hpVisitationConvertor.toDb({
            websiteAdvertised: data.websiteAdvertised,
            websites: data.websites,
          })
        : undefined,
      hpDocPacketLetter: data.hpDocPacketLetter,
      receivedAfterCompletingQuestionnaireOrInterview:
        data.receivedAfterCompletingQuestionnaireOrInterview === 'yes',
    };
  },
};

export const unAuthorizedResidencyConverter: TFetchConverter<
  TUnAuthorizedResidencyUI,
  TUnAuthorizedResidencyDB
> = {
  fromDb: data => {
    return {
      propertyManagementName: data.propertyManagementName,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
    };
  },
};

export const incompleteAnimalsData: TFetchConverter<
  TIncompleteAnimalsDataUI,
  TIncompleteAnimalsDataDB
> = {
  fromDb: data => {
    return {
      animalId: data.animalId || '',
      applicationId: data.applicationId || '',
      incompleteResource:
        data.incompleteResource === 'ANIMAL' ? 'ANIMAL' : 'APPLICATION',
      count: data.count,
    };
  },
};

export const animalDuplicateListConverter: TFetchConverter<
  TDuplicationPreventionUI,
  TDuplicationPreventionDB[]
> = {
  fromDb: data => {
    return {
      animalId: data[0].animalId,
      animalName: data[0].animalName,
      animalSpice: data[0].animalSpice,
      animalType: animalTypesConvertor.fromDb(data[0].animalType),
      ownerName: data[0].ownerName,
      ownerUsername: emailMaskConverter.fromDb(data[0].ownerUsername),
      status: applicationStatusConvertor.fromDb(data[0].status),
    };
  },
};

export const checkDuplicationConvertor: TCreateConverter<
  TDuplicateCheckUI,
  TDuplicateCheckDB
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      tenantResidencyId: data.residencyId,
    };
  },
};

export const deleteAnimalConvertor: TCreateConverter<
  TDeleteAnimalUI,
  TDeleteAnimalDB
> = {
  toDb: data => {
    return {
      animalId: data.animalId,
      ...(data.moveOutReason && {
        moveOutReason: data.otherReason
          ? { other: data.otherReason }
          : {
              predefined: moveOutOrRetractReasonTypeConverter.toDb(
                data.moveOutReason,
              ),
            },
      }),
      retractionReason: data.retractionReason,
    };
  },
};

export const animalConvertedAcknowledgmentConverter: TFetchConverter<
  TConvertedToPetAcknowledgmentUI,
  TConvertedToPetAcknowledgmentDB
> = {
  fromDb: data => {
    const isPredefined = Object.values(EConversionReasonDB).includes(
      data.convertedToPetReason as EConversionReasonDB,
    );

    return {
      name: data.name,
      convertedToPetReason: isPredefined
        ? animalConversionReason.fromDb(
            data.convertedToPetReason as EConversionReasonDB,
          )
        : data.convertedToPetReason,
      id: data.id,
      species: data.species.value,
      isReasonPredefined: isPredefined,
    };
  },
};
