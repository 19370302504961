import { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import useForm from 'hooks/useForm';
import { useDispatch } from 'react-redux';
import { EText } from 'data/datas/genericData';
import { EAnimalTypesUI, TConvertAnimalToEsaUI } from 'data/types/animal.types';
import { RoutesPaths } from 'routes/Routes.types';
import { Paragraph, Title } from 'components/Base/Typography';
import { ETabActiveKeyContent } from 'components/Shared/AnimalDetails/AnimalOtherInfo/AnimalOtherInfo.data';
import {
  animalApi,
  useConvertAnimalToESAMutation,
  useUpdateAnimalToESAConversionMutation,
} from 'redux/apiSlice/animal.slice';
import { TValidateFieldsError } from 'data/types/generalTypes';
import Modal, { ModalWidthEnum } from 'components/Base/Modal';
import toast from 'components/Base/Notification';
import HasESADocumentationFrom from './HasESADocumentationFrom';
import { TDoYouHaveDocumentsModalProps } from './DoYouHaveDocumentsModal.types';

const DoYouHaveDocumentsModal = forwardRef(
  (
    {
      conversion,
      handleNotificationEvent,
      onChangeTab,
      afterClose = () => {
        // do nothing
      },
    }: TDoYouHaveDocumentsModalProps,
    ref,
  ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [animalId, setAnimalId] = useState('');
    const [open, setOpen] = useState(false);
    const { form } = useForm<TConvertAnimalToEsaUI>();
    const [openCancelConversionModal, setOpenCancelConversionModal] =
      useState(false);
    const [convertToESA, { isLoading: convertToESALoading }] =
      useConvertAnimalToESAMutation();
    const [updateEsa, { isLoading: updateEsaLoading }] =
      useUpdateAnimalToESAConversionMutation();

    useImperativeHandle(ref, () => ({
      open: (id: string) => {
        setOpen(true);
        setAnimalId(id);
      },
    }));

    const onConvertToESA = async () => {
      try {
        const values = await form.validateFields();
        if (values.requiredBecauseOfDisability) {
          navigate(
            `/app/${RoutesPaths.ANIMAL}/${RoutesPaths.CONVERSION}?type=${EAnimalTypesUI.ESA}&id=${animalId}`,
            { state: { conversionId: conversion?.id || undefined } },
          );
          return;
        }
        if (conversion?.id) {
          await updateEsa({
            conversionId: conversion.id,
            ...values,
            requiredBecauseOfDisability: false,
            animalId,
          });
        } else {
          await convertToESA({
            ...values,
            requiredBecauseOfDisability: false,
            animalId,
          });
        }
        if (onChangeTab) {
          onChangeTab(ETabActiveKeyContent.CONVERSION);
        }
        setOpen(false);
        setOpenCancelConversionModal(true);
      } catch (e) {
        setOpen(false);
        if (!(e as TValidateFieldsError)?.errorFields) {
          const message = get(e, 'data.message', EText.DEFAULT_ERROR) as string;
          toast({
            description: message,
            type: 'error',
          });
        }
      }
    };

    const onSubmit = async () => {
      if (handleNotificationEvent) {
        handleNotificationEvent();
      }
      try {
        await onConvertToESA();
        setOpen(false);
        dispatch(animalApi.util.invalidateTags(['animal']));
      } catch (e) {
        if (!(e as TValidateFieldsError)?.errorFields) {
          const message = get(e, 'data.message', EText.DEFAULT_ERROR) as string;
          toast({
            description: message,
            type: 'error',
          });
        }
      }
    };

    const handleAfterClose = () => {
      setOpen(false);
      afterClose();
      if (handleNotificationEvent) {
        handleNotificationEvent();
      }
      form.resetFields();
    };

    return (
      <>
        <Modal
          destroyOnClose
          visible={openCancelConversionModal}
          onCancel={() => setOpenCancelConversionModal(false)}
          footer={null}
          size={ModalWidthEnum.Small}
        >
          <Title className="mb-6 text-center" fontLevel={3}>
            Your conversion request cannot be processed.
          </Title>
          <Paragraph fontLevel={4} className="text-center">
            {conversion?.id
              ? 'The conversion request will not be approved since the animal has to be required for a disability to be considered an assistance animal.'
              : 'The animal has to be required for a disability to be considered an assistance animal.'}
          </Paragraph>
        </Modal>
        <Modal
          destroyOnClose
          title="ESA documentation"
          size={ModalWidthEnum.Small}
          visible={open}
          onOk={onSubmit}
          confirmLoading={convertToESALoading || updateEsaLoading}
          afterClose={() => form.resetFields()}
          okText="Submit"
          onCancel={() => handleAfterClose()}
        >
          <HasESADocumentationFrom form={form} conversion={conversion} />
        </Modal>
      </>
    );
  },
);

export default DoYouHaveDocumentsModal;
