import { useNavigate } from 'react-router-dom';
import { Row } from 'components/Base/Grid';
import { TIconNames } from 'components/Base/Icon';
import Image from 'components/Base/Image';
import { TCollapsedMenuHeaderProps } from 'components/Base/Layout/Header/Header.props';
import { RoutesPaths } from 'routes/Routes.types';
import logo from 'assets/logo.svg';
import { SCloseIcon } from '../../MobileHeader.styles';

const CollapsedMenuHeader = ({ handleToggle }: TCollapsedMenuHeaderProps) => {
  const navigate = useNavigate();
  const onLogoClick = () => {
    handleToggle(false);
    navigate(`/app/${RoutesPaths.HOME}`);
  };

  return (
    <Row justify="space-between" align="middle" className="pb-8">
      <Image src={logo} alt="opp-logo" onClick={onLogoClick} />
      <SCloseIcon
        icon={TIconNames.CLOSE_CIRCLE}
        size={24}
        onClick={() => handleToggle(false)}
      />
    </Row>
  );
};

export default CollapsedMenuHeader;
