import { TCollapsedMenuItemsProps } from 'components/Base/Layout/Header/Header.props';
import { Colors } from 'core/CssVariables';
import useFilterRoutes from 'hooks/useFilterRoutes';
import { PrivateRouteType } from 'routes/Routes.types';
import { SMenuWrapper } from '../CollapsedMenu.styles';
import CollapsedMenuPathItem from '../../../MenuItem/MenuPathItem';

const CollapsedMenuPrimary = ({
  handleToggle = () => {
    /* do nothing */
  },
}: TCollapsedMenuItemsProps) => {
  const routes = useFilterRoutes();

  const renderMenuItems = (items: PrivateRouteType[]) => {
    return items.map(route => {
      if (route.subRoutes && route.subRoutes.length > 0) {
        return (
          <div key={route.path}>
            <CollapsedMenuPathItem
              handleToggle={handleToggle}
              menuItem={route}
              hasSubMenu
            />
            <div
              style={{
                marginLeft: '32px',
                borderLeft: `1px solid ${Colors.LightGrey}`,
              }}
            >
              {route.subRoutes.map(subRoute => (
                <CollapsedMenuPathItem
                  key={subRoute.path}
                  handleToggle={handleToggle}
                  menuItem={subRoute}
                />
              ))}
            </div>
          </div>
        );
      }
      return (
        <CollapsedMenuPathItem
          key={route.path}
          handleToggle={handleToggle}
          menuItem={route}
        />
      );
    });
  };

  const menuItems = routes.filter(item => item.showMobileMenu);

  return <SMenuWrapper>{renderMenuItems(menuItems)}</SMenuWrapper>;
};

export default CollapsedMenuPrimary;
