import { useEffect, useState } from 'react';
import {
  isDisabilityObservableOptions,
  yesNoTrueFalseOptions,
} from 'data/datas/genericData';
import Form, { FormItem } from 'components/Base/Form';
import { Text } from 'components/Base/Typography';
import { TextArea } from 'components/Base/TextField';
import { RadioGroup } from 'components/Base/Radio';
import RadioLabel from 'components/Shared/RadioLabel';
import { TSADocumentationFormProps } from './SADocumentationForm.types';
import { SRadio } from './SADocumentationForm.styles';
import { hasSADocumentationFormFields } from './SADocumentationForm.data';

const SADocumentationForm = ({
  conversion,
  form,
}: TSADocumentationFormProps) => {
  const { setFieldsValue } = form;
  const [isRequiredForDisability, setIsRequiredForDisability] = useState<
    boolean | undefined
  >();
  const {
    observableDisability,
    trainedFor,
    requiredBecauseOfDisability: isRequired,
  } = hasSADocumentationFormFields;

  useEffect(() => {
    if (!conversion) {
      return;
    }
    setFieldsValue({
      observableDisability: !!conversion?.observableDisability,
      trainedFor: conversion.trainedFor || '',
      requiredBecauseOfDisability: true,
    });
    setIsRequiredForDisability(true);
  }, [conversion]);

  return (
    <Form
      form={form}
      name="sa-documentation-form"
      autoComplete="off"
      requiredMark={false}
      initialValues={{
        isObservable: conversion?.observableDisability || null,
      }}
      id="sa-documentation-form"
      scrollToFirstError
    >
      <Text fontLevel={5}>Is the animal required because of a disability?</Text>
      <FormItem
        {...isRequired}
        rules={[
          {
            type: 'boolean',
            required: true,
            message: 'This field is required!',
          },
        ]}
        className="mb-4 mb-0"
      >
        <RadioGroup onChange={e => setIsRequiredForDisability(e.target.value)}>
          {yesNoTrueFalseOptions.map(({ value, label }) => (
            <SRadio value={value} label={label} key={label} />
          ))}
        </RadioGroup>
      </FormItem>
      {isRequiredForDisability && (
        <>
          <FormItem {...trainedFor} className="mt-4">
            <TextArea placeholder="Type here" />
          </FormItem>
          <Text fontLevel={5}>
            Is this an observable disability or non observable disability?
          </Text>
          <FormItem {...observableDisability} className="mt-2 mb-0">
            <RadioGroup>
              {isDisabilityObservableOptions.map(
                ({ value, label, subtitle }) => (
                  <SRadio
                    value={value}
                    label={<RadioLabel label={label} subtitle={subtitle} />}
                    key={label}
                  />
                ),
              )}
            </RadioGroup>
          </FormItem>
        </>
      )}
    </Form>
  );
};

export default SADocumentationForm;
